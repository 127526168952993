
.WidgetsBar  {
	padding: 15px 20px;
	border-bottom: 1px solid #eee;
	background-color: white;
	margin-bottom: 16px;
	height: 140px;
	position: relative;
	box-shadow: 0 4px 25px 0 rgb(0 0 0 / 10%);
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
}

.WidgetsBar .SearchContainer {
	width: 20%;
	max-width: 330px;
	min-width: 200px;
	right: 0px;
	top: 0px;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.PaginationWrapper {
	padding-top: 20px;
	width: 100%;
}

.PaginationWrapper :global(.ant-pagination-item:not(.ant-pagination-item-active) a){
	color: var(--kemu-color-primary);
}

.PaginationWrapper {
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: flex;
	justify-content: flex-end;
}

.PaginationWrapper ul {
	line-height: 28px;
	display: flex;
	padding-left: 0;
	list-style: none;
	border-radius: 0.25rem;
	width: 100%;
	justify-content: flex-end;
}

.PaginationWrapper .Break {
	/* display: none; */
}

.PaginationWrapper .Next {
	/* display: none !important; */
}

.PaginationWrapper .PageItem {
	color: #6777ef;
	border-radius: 3px;
	margin: 0 3px;
	-webkit-box-shadow: 2px 2px 2px rgba(0,0,0,0.2);
	box-shadow: 2px 2px 2px rgba(0,0,0,0.2);
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
  justify-content: center;
}

.PaginationWrapper .PageItem :global(.page-link) {
	border-color: transparent;
	font-weight: 600;
	position: relative;
	display: block;
	padding: .5rem .75rem;
	margin-left: -1px;
	line-height: 1.25;
	color: var(--kemu-color-primary);
	user-select: none;
}

.PaginationWrapper .PageItem:global(.active a) {
	color: white;
}

.PaginationWrapper .PageItem:global(.disabled a) {
	pointer-events: none;
	color: #888e93;
}

.PaginationWrapper .PageItem:global(.active) {
	background-color: var(--kemu-color-primary);
}



.WidgetsBar :global(.gates-container) {
	width: 80%;
	height: 100%;
	overflow-y: visible;
}

.WidgetsBar :global(.gates-container .gates-list) {
	width: 100%;
	height: 100%;
	overflow: hidden;
	padding-top: 4px;
}

.WidgetsBar :global(.logic-gate-element) {
	border-radius: 10px;
	position: relative;
	text-align: center;
	width: 70px;
	float: left;
	margin-left: 12px;
	cursor: pointer;
	transition: transform 0.35s;
	transition-delay: 100ms;
	will-change: transform;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding: 5px 0px;
}

.WidgetsBar :global(.logic-gate-element .logic-gate-icon) {
	transition: transform 0.35s;
	transition-delay: 100ms;
	will-change: transform;
}

.WidgetsBar :global(.logic-gate-element:hover .logic-gate-icon) {
	transform: scale(1.2);
	transition-delay: 0ms;
	-webkit-font-smoothing: subpixel-antialiased;
}

.WidgetsBar :global(.logic-gate-element .logic-gate-icon) {
	font-size: 23px;
	color: white;
	padding: 10px;
	border-radius: 7px;
	width: 60px;
	margin: 0 auto;
	height: 50px;
	box-shadow: 2px 2px 2px rgba(0,0,0,0.2);
}

.WidgetsBar :global(.logic-gate-element .gate-name) {
	white-space: normal;
	margin-top: 7px;
	margin-bottom: 3px;
	font-weight: 600;
	color: #757575;
	position: relative;
	line-height: normal;
}

.WidgetsBar :global(.logic-gate-element .logic-gate-icon span.anticon) {
	font-size: 30px;
}

.WidgetsBar :global(.logic-gate-element.ctgry-logic .logic-gate-icon) { background-color: var(--kemu-color-widget-type-logic); }
.WidgetsBar :global(.logic-gate-element.ctgry-condition .logic-gate-icon) { background-color: var(--kemu-color-widget-type-condition); }
.WidgetsBar :global(.logic-gate-element.ctgry-producer .logic-gate-icon) { background-color: var(--kemu-color-widget-type-producer); }
.WidgetsBar :global(.logic-gate-element.ctgry-consumer .logic-gate-icon) { background-color: var(--kemu-color-widget-type-consumer); }
.WidgetsBar :global(.logic-gate-element.ctgry-storage .logic-gate-icon) { background-color: var(--kemu-color-widget-type-storage); }
.WidgetsBar :global(.logic-gate-element.ctgry-transformer .logic-gate-icon) { background-color: var(--kemu-color-widget-type-transformer); }
.WidgetsBar :global(.logic-gate-element.ctgry-custom .logic-gate-icon) { background-color: var(--kemu-color-widget-type-custom); }