.GateBody {
	display: flex;
	width: 100px;
}

.InputContainer {
	position: relative;
}

.Suffix {
	position: absolute;
	right: 0px;
	bottom: 0px;
	height: 100%;
	background-color: #ffffff;
	text-align: center;
	cursor: pointer;
	padding: 0px 3px;
	color: #666666;
	line-height: 28px;
}

.PlayButton {
	position: absolute;
	left: 0px;
	cursor: pointer;
	height: 100%;
	font-size: 16px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0px 3px;
	top: 0px;
	z-index: 1;
}

.Suffix:global(.isRunning){
	cursor: default;
	pointer-events: none;
}