.Light{
	background-color: #f6f6f6;
	color: #6c757d;
}

.Container {
	height: 100%;
}

.Container.light-theme {
	font-size: 14px;
	font-weight: 400;
	font-family: 'Nunito','Segoe UI',arial;
	box-sizing: border-box;
} 

.CardContainer{
	margin: auto;
}

.Section {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}