.TextField {
  height: auto;
  border: none;
  outline: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  color: #666;
  font-size: 14px;
  font-weight: 400;
  padding: 4px;
  text-align: center;
}

textarea.TextField {
  resize: both;
  text-align: left;
  transition: none;
  min-width: 130px;
  width: 100%;
  height: 100%;
}

.SingleElement {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
