.DetailsPageContainer {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.Navigation {
  margin-bottom: 20px;
}

.PageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.PageBody {
  width: 100%;
  max-width: 1200px;
  margin-bottom: 50px;
  padding-bottom: 20px;
}

/* Header Styles */
.Header {
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;
}


.IconWrapper {
  flex: 0 0 105px;
  width: 105px;
  height: 105px;
  background-color: white;
  border-radius: 10px;
  padding: 4px;
  border: 1px solid #80808026;
  margin-right: 20px;
}

.IconWrapper.NoBorder {
  border: none;
}

.TitleSection {
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
  min-width: 0;
}

.Title {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}

.Author {
  display: flex;
  align-items: center;
  gap: 6px;
  color: #666;
}

.Rating {
  display: flex;
  align-items: center;
  gap: 8px;
}

.ReviewCount {
  color: #666;
}

.InstallBtnContainer {
  width: 120px;
  text-align: right;
}

/* Preview Section */
.PreviewSection {
  margin: 24px 0;
}

.PreviewImageContainer {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #f5f5f5; */
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
}

.PreviewImage {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

/* Description Section */
.Description {
  margin: 24px 0;
  overflow: hidden;
}

.Description h3 {
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 600;
}

/* Ratings Section */
.RatingsSection {
  margin: 24px 0;
}

.RatingsSection h3 {
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 600;
}

/* Change Log Section */
.ChangeLog {
  margin: 24px 0;
}

.ChangeLog h3 {
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 600;
}

.IconSkeleton {
  width: 105px !important;
  height: 105px !important;
}

.PreviewSkeleton {
  width: 100% !important;
  height: 400px !important;
}


@media (max-width: 768px) {
  .Header {
    flex-direction: column;
    gap: 12px;
  }

  .InstallBtnContainer {
    width: 100%;
    text-align: left;
  }

  .DetailsPageContainer {
    margin-top: 24px;
  }
}
