:root {
  --status-indicator-size: 15px;
  --status-indicator-animation-duration: 2s;

  --status-indicator-color: rgb(216, 226, 233);
  --status-indicator-color-semi: rgba(216, 226, 233, .5);
  --status-indicator-color-transparent: rgba(216, 226, 233, 0);

  --status-indicator-color-idle: rgb(184, 184, 184);
  --status-indicator-color-idle-semi: rgba(184, 184, 184, .5);
  --status-indicator-color-idle-transparent: rgba(184, 184, 184, 0);

  --status-indicator-color-positive: rgb(75, 210, 143);
  --status-indicator-color-positive-semi: rgba(75, 210, 143, .5);
  --status-indicator-color-positive-transparent: rgba(75, 210, 143, 0);

  --status-indicator-color-warning: rgb(255, 170, 0);
  --status-indicator-color-warning-semi: rgba(255, 170, 0, .5);
  --status-indicator-color-warning-transparent: rgba(255, 170, 0, 0);

  --status-indicator-color-negative: rgb(255, 77, 77);
  --status-indicator-color-negative-semi: rgba(255, 77, 77, .5);
  --status-indicator-color-negative-transparent: rgba(255, 77, 77, 0);
}

.Container{
	width: 100%;
	height: 70px;
	line-height: 70px;
	display: flex;
	align-items: center;
	justify-content: center;
}

:global(.main-sidebar:not(.ant-layout-sider-collapsed)) .Dot{
	display: none;
}

.Dot{
	width: var(--status-indicator-size);
	height: var(--status-indicator-size);
	border-radius: 50%;
	display: inline-block;
}

@keyframes status-indicator-pulse {
  0%   { box-shadow: 0 0 0 0 var(--status-indicator-color-semi); }
  70%  { box-shadow: 0 0 0 var(--status-indicator-size) var(--status-indicator-color-transparent); }
  100% { box-shadow: 0 0 0 0 var(--status-indicator-color-transparent); }
}

@keyframes status-indicator-pulse-idle {
  0%   { box-shadow: 0 0 0 0 var(--status-indicator-color-idle-semi); }
  70%  { box-shadow: 0 0 0 var(--status-indicator-size) var(--status-indicator-color-idle-transparent); }
  100% { box-shadow: 0 0 0 0 var(--status-indicator-color-idle-transparent); }
}

@keyframes status-indicator-pulse-positive {
  0%   { box-shadow: 0 0 0 0 var(--status-indicator-color-positive-semi); }
  70%  { box-shadow: 0 0 0 var(--status-indicator-size) var(--status-indicator-color-positive-transparent); }
  100% { box-shadow: 0 0 0 0 var(--status-indicator-color-positive-transparent); }
}

@keyframes status-indicator-pulse-warning {
  0%   { box-shadow: 0 0 0 0 var(--status-indicator-color-warning-semi); }
  70%  { box-shadow: 0 0 0 var(--status-indicator-size) var(--status-indicator-color-warning-transparent); }
  100% { box-shadow: 0 0 0 0 var(--status-indicator-color-warning-transparent); }
}

@keyframes status-indicator-pulse-negative {
  0%   { box-shadow: 0 0 0 0 var(--status-indicator-color-negative-semi); }
  70%  { box-shadow: 0 0 0 var(--status-indicator-size) var(--status-indicator-color-negative-transparent); }
  100% { box-shadow: 0 0 0 0 var(--status-indicator-color-negative-transparent); }
}

.Dot:global(.pulse) {
  animation-name: status-indicator-pulse;
	animation-duration: var(--status-indicator-animation-duration);
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
	animation-direction: normal;
	animation-delay: 0;
	animation-fill-mode: none;
}

.Dot:global(.idle) {
  background-color: var(--status-indicator-color-idle);
}

.Dot:global(.idle.pulse) {
  animation-name: status-indicator-pulse-idle;
}

.Dot:global(.positive) {
  background-color: var(--status-indicator-color-positive);
  /* animation-name: status-indicator-pulse-positive; */
}

.Dot:global(.positive.pulse) {
  animation-name: status-indicator-pulse-positive;
}

.Dot:global(.warning) {
  background-color: var(--status-indicator-color-warning);
}

.Dot:global(.warning.pulse) {
  animation-name: status-indicator-pulse-warning;
}

.Dot:global(.negative) {
  background-color: var(--status-indicator-color-negative);
  animation-name: status-indicator-pulse-negative;
}

.Dot:global(.negative.pulse) {
  animation-name: status-indicator-pulse-negative;
}