.CardContainer {
  height: 100%;
  width: 100%;
}

.ServiceCard {
  margin-bottom: 16px;
  border-radius: 8px;
  overflow: hidden;
  height: 100%;
}

.ImageContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #eeeeee;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 245px;
}

.MainImage {
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  min-height: 260px;
}

.Details {
  margin-bottom: 12px;
}

.Header {
  display: flex;
  align-items: flex-start;
  margin-bottom: 4px;
}

.IconWrapper {
  flex: 0 0 105px;
  width: 105px;
  height: 105px;
  background-color: white;
  border-radius: 10px;
  padding: 4px;
  border: 1px solid #80808026;
  margin-right: 20px;
}

.TitleSection {
  display: flex;
  flex-direction: column;
  gap: 2px;
  flex: 1;
  min-width: 0;
}

.Title {
  margin: 0;
  font-size: 16px;
  font-weight: 700;
}

.CategoryBtn {
  margin: 0;
  font-size: 14px;
  height: 24px;
  align-self: flex-start;
  padding: 0;
}

.Price {
  font-weight: 500;
  color: #0066CC;
}

.Rating {
  display: flex;
  align-items: center;
  gap: 4px;
}

.ReviewCount {
  color: #666;
  font-size: 14px;
}


.InstallBtnContainer {
  width: 100px;
  text-align: right;
}

.Author {
  display: flex;
  align-items: center;
  gap: 6px;
}

.Description {
  margin-bottom: 12px;
  font-size: 15px;
  font-weight: 500;
  line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* number of lines to show */
  overflow: hidden;
  /* color: #666; */
}