
.SearchInputWrapper {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding-top: 5px;
	/* padding-top: 12px;
	padding-left: 16px; */
}

.SearchInput {
	width: 100%;
	max-width: 150px;
	border-radius: 25px;
	border: none;
	padding: 3px 10px;
	opacity: 0.6;
	transition: all 0.3s ease-in-out;
	transition-property: opacity, padding, max-width;
}

.SearchInput:hover {
	opacity: 1;
}

/* Prevent border when active */
.SearchInput:focus {
	outline: none;
	padding: 5px 15px;
	max-width: 200px;
}

.WidgetsSection {
	min-height: 150px;

	height: calc(100% - 100px);
	overflow-y: auto;
}

.WidgetsGrid {
	width: 100%;
	min-height: 200px;
	display: flex;
	flex-wrap: wrap;
	overflow: auto;
	align-content: flex-start;
	justify-content: flex-start;
	margin-top: 20px;
	padding-top: 20px;
  padding-left: 16px;
	gap: 20px 0px;
	padding-bottom: 50px;
	position: relative;
}

.EmptyWidgetsContainer { 
	width: 100%;
	height: 250px;
	text-align: center;
	display: flex;
	flex-direction: column;
	color: var(--widget-launchpad-section-header-color);
	justify-content: center;
	align-items: center;
}



.ActionIcons {
	display: inline-flex;
	flex-direction: row;
	gap: 8px;
}

.CustomSvg {
	width: 16px;
	height: 16px;
}