.ButtonWrapper {
  display: flex;
	align-items: center;
}

.HasBreadcrumb {
  margin-right: 5px;
}

.ContentBody {
  padding: 5px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.Row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  justify-content: space-between;
}

.Button {
  /* color: var(--kc-custom-widget-mode-canvas-breadcrumb-color); */
  color: #7d848b;
}