.Container{
	width: 100% !important;
}

.Container :global(input) {
	border-width: 1px !important;
	border-style: solid !important;
	border-color: #ffffff5e !important;
	transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.Container :global(input:focus) {
	border: 1px white solid !important;
	box-shadow: none !important;
	outline: 0;	
	transition: none !important;
}