.PortContainer {
	min-width: 20px;
	height: 24px;
	background-color: var(--kemu-color-primary);
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px 7px;
	color: white;
	border-radius: 6px;
	cursor: move;
	user-select: none;
}