.DropdownField {
  height: auto;
  border: none;
  outline: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  color: #666;
  font-size: 14px;
  font-weight: 400;
  padding: 4px;
  text-align: center;
}

.DropdownItem.NotSelected {
	background-color: #fff;
	color: #262626;
}

.DropDownTrigger {
	background-color: #fff;
	color: #262626;
	width: 100%;
	height: 34px;
	display: inline-flex;
	line-height: 20px;
	text-align: center;
	cursor: pointer;
	border-radius: 3px;
	padding: 0px 5px;
	justify-content: center;
	align-items: center;
  position: relative;
}

.DropIcon {
  position: absolute;
	top: calc(50% - 6px);
	right: 2px;
	margin-left: 10px;
	margin-right: 6px;
	cursor: pointer;
}

.SelectedOption {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 15px;
  text-align: center;
  text-wrap: nowrap;
}

.SingleDropDown {
	border-top-right-radius: 0px;
	border-top-left-radius: 0px;
}

.Menu {
	max-height: 250px;
	overflow-y: auto;
}