.GroupBody {
  width: 112px;
  border-radius: 4px;
  overflow: hidden;
  height: 100%;

  display: flex;
  flex-direction: column;
}

.BrightBody {
  border: 2px solid;
}

.DisabledWidget .GroupBody {
  background-color: var(--kemu-color-disabled-widget);
}

.Content {
  display: flex;
  flex-direction: column;
  padding: 4px 6px;
  padding-bottom: 6px;
  gap: 5px;

  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  align-items: center;
  justify-content: center;
  height: 100%;
}
