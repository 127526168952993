.Swatch {
	background: #fff;
	border-radius: 2px;
	box-shadow: 0 0 0 1px rgb(0 0 0 / 10%);
	display: inline-block;
	cursor: pointer;
	height: 20px;
	border: 4px solid white;
	width: 100%;
}

.Popover {
	position: absolute;
	z-index: 2;
}