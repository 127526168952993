.CustomWidgetUI {
	min-width: 50px;
	min-height: 50px;
}

.Hidden {
	display: none;
}

.ShortTitle {
	font-family: 'Nunito';
	font-style: normal;
	font-weight: 600;
	text-align: center;
	position: absolute;
	bottom: -25px;
	transform: translateY(50%);
	pointer-events: none;
	color: var(--kc-canvas-gate-port-label);
	width: max-content;
	/* width: 100%; */
	/* min-width: 160px; */
}

.CustomWidgetUI:hover + .ShortTitle {
	color: var(--kemu-color-primary);
}

.CustomWidgetUI:hover + .ShortTitle.InsideGroup {
	color: var(--kemu-color-primary-lighten-30);
}

.ShortTitle.InsideGroup {
	color: #f0e9e9;
}