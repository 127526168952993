.GateBody {
  display: flex;
  width: 150px;
  /* height: 60px; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--kemu-color-widget-type-logic);
  color: white;
}

.WidgetDisabled {
  background-color: var(--kemu-color-disabled-widget);
}

.Row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 10px;
  /* border: 1px solid white; */
  padding: 3px;
	border-bottom: 1px solid #ffffff42;
  padding-bottom: 5px;
}

.Row:last-child {
	border-bottom: none;
	margin-top: auto;
}

.Row .Label {
  justify-content: flex-start;
  color: white;
	font-weight: 700;
}

.Row .Element {
  /* justify-content: flex-end; */
	display: flex;
	flex-direction: row;
	align-items: center;
  /* width: 100%; */
  /* color: white; */
}

.Row .Element input {
  width: 100%;
}

.TypesMenu {
	color: #60686f;
	font-size: 10px;
}

.ChooseType {
	background-color: #fff;
	color: var(--kemu-color-secondary);
	width: 34px;
	height: 33px;
	display: inline-flex;
	line-height: 20px;
	text-align: center;
	cursor: pointer;
	border-radius: 3px;
	padding: 0px 5px;
  font-size: 24px;
	justify-content: center;
	align-items: center;
}


/* ImageData */
.ChooseType:global(.type-7) {
	font-size: 13px;
	padding: 0px 5px;
}

.ChooseType:global(.type-0) {
	font-size: 15px;
}

.SettingsContainer {
	min-height: 130px;
	padding: 30px 10px 10px 10px;
	background-color: var(--kemu-color-primary-darken-80);
	position: relative;
	left: -45px;
	top: -2px;
	height: 230px;
	width: 250px;
	z-index: 2;
	/* Simulate gate border */
	border: 2px solid var(--kemu-color-primary-lighten-10);
	
	display: flex;
  flex-direction: column;
}

.TypeName {
  color: #ffffff;
	margin-right: 28px;
	font-size: 14px;
}

.SwitchContainer {
	display: flex;
	flex-direction: column;
	align-items: baseline;
	padding-top: 10px;
}

.DropdownMenu :global(li.kemu-dropdown-menu-item-selected){
	background-color: var(--kemu-color-widget-type-producer-settings-input);
	color: white;
}

span.InputElement {
	width: 100%;
	border-radius: 0;
}

span.InputElement input {
	text-align: center;
}

/* Owner Of the variable */
.InputElement.VarOwner,
.InputElement.VarOwner input {
	background-color: #fbde74;
}

.InputElement.VarOwner input::placeholder {
	color: rgba(0,0,0,.45) !important;
}

.InputElement.VarOwner .DropArrow {
	color: rgba(0,0,0,.85)
}

.DefaultValue {
	outline: none;
	border: none;
	padding: 2px;
	padding-left: 8px;
}

.SaveBtn {
	margin-top: 10px;
  margin-left: auto;
}

.VarType {
	float: right;
	font-size: 10px;
	font-weight: 700;
}