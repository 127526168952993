.Wrapper {
  position: relative;
}

.Input {
	font-size: 14px;
	padding: 10px 5px;
	height: 25px;

	
	display: block;
	width: 100%;
	
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	
	background-clip: padding-box;
	border-width: 1px;
	border-style: solid;;
	border-color: #ffffff5e;

	border-radius: .25rem;

	transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
	outline-offset: -2px;
}

.Input:focus {
	background-color: #fefeff;
	border-color: #fff;
	color: #495057;
	box-shadow: none !important;
  outline: 0;
}

.EyeBtn {
  cursor: pointer;
  position: absolute;
  right: 6px;
  bottom: 0px;
  color: white;
}