.GateBody {
	display: flex;
  width: 62px;
  height: 40px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: var(--kemu-color-widget-type-transformer);
	color: white;
	padding-bottom: 1px;
}

.DisabledWidget {
	background-color: var(--kemu-color-disabled-widget);
}

.LargeGateBody {
	height: 62px;
	justify-content: space-evenly;
	padding-bottom: 10px;
}

.GateBody svg {
	width: 38px;
	height: 38px;
}

/* Force settings container above ports when a port is dynamically added */
:global(.image-crop-widget-wrapper .cg-container .cg-header) {
	padding-right: 10px;
}

:global(.image-crop-widget-wrapper.settings-visible) {
	z-index: 303;
}

.CanvasWrapper {
	width: 100%;
	height: 100%;
}

.CanvasWrapper button {
	margin-left: 25px;
	margin-top: 10px;
}

.Canvas {
	width: 100%;
	/* height: 200px; */
	background-color: black;
}

.SettingsHeader {
	height: 20px;
	width: 100%;
}

.SettingsModalBody {
	padding-top: 20px;
	padding-left: 0px;
	padding-right: 0px;
	padding-bottom: 0px;
}

.FooterWrapper {

}