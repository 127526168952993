
.ServiceButton:hover {
	z-index: 100;
}

/* .ServiceButton:global(.text-expanded .gate-name){
	padding: 2px 5px 4px;
	margin-top: 9px;
	width: 110px;
} */


.ServiceButton:global(.disabled) {
	opacity: 0.35;
}

.ServiceButton {
	border-radius: 10px;
	position: relative;
	text-align: center;
	width: 75px;
	cursor: pointer;
	transition: transform 0.35s;
	transition-delay: 100ms;
	will-change: transform;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding: 5px 0px;
	margin: 6px;
	height: 100px;
}


.ServiceButton .Icon::after {
	content: '';
	border-radius: 7px;
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	border-width: 3px;
	border-style: solid;
	border-color: white;
	opacity: 0;
	transition: opacity 0.1s ease-in-out;
	will-change: opacity;
	margin-left: -3px;
  margin-top: -3px;
}

.ServiceButton.Selected .Icon::after{
	box-shadow: 0px 0px 12px 8px hsl(0deg 0% 0% / 20%);
	opacity: 1;
}

.ServiceButton:hover .Icon {
	transform: scale(1.2);
	-webkit-font-smoothing: subpixel-antialiased;
}

.ServiceName {
	white-space: normal;
	margin-top: 7px;
	margin-bottom: 3px;
	font-weight: 600;
	color: var(--widget-launchpad-button-text-color, gray);
	position: relative;
	line-height: normal;
}

/* .ServiceButton :global(.logic-gate-icon) span.anticon{
	font-size: 30px;
} */

.ServiceButton .Icon {
	transition: transform 0.35s ease-in-out 100ms;
	will-change: transform;

	font-size: 25px;
	line-height: 25px;
	color: white;
	background-color: var(--kemu-color-secondary);
	padding: 10px;
	border-radius: 7px;
	width: 60px;
	margin: 0 auto;
	height: 50px;
}