.Centered{
	align-items: center;
	justify-content: space-between;
	display: flex;
}

.Button {
	float: right;
}

.TryAgain{
	
}

.LoadingIcon{
	font-size: 32px;
}