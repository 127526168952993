
.LoadingContainer {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}

.LoadingContainer p {
	display: inline-block;
}

.AgreeContainer {
	padding-top: 10px;
	display: flex;
	align-items: center;
	flex-wrap: inherit !important;
}

.AgreeContainer label{
	margin-bottom: 0px !important;
}

.AgreeLabel{
	padding-left: 10px;
}

.ButtonContainer{
	margin-top: 20px;
	width: 100%;
	display: flex;
	flex-direction: column;
}

.ButtonContainer :global(.ant-btn-loading-icon){
	margin-right: 10px;
}

.GoHomeButton {
	margin-top: 30px;
	text-align: right;
}
