.Ribbon:global(.published){
	background-color: var(--kemu-color-primary);
}

.Ribbon:global(.pending){
	background-color: var(--kemu-color-warning);
}

.Ribbon:global(.rejected){
	background-color: var(--kemu-color-danger);
}

.Ribbon a{
	color: white;
}

.NoRibbon{
	display: none;
}