.ButtonContainer {
	text-align: center;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.Button {
	height: 36px;
}

.ButtonContainer button {
	color: var(--kemu-color-widget-type-producer);
}