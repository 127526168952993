.CloudWrapper {
	padding-right: 5px;
	cursor: pointer;
	display: flex;
}

.CloudWrapper svg path {
	transition: fill 0.15s ease-in-out;
}

.CloudWrapper:hover svg path {
	fill: #34395e;
}

.CloudWrapper:active svg path {
	fill: #494b58;
}


/* .CloudIcon svg :global(.gear) {
	fill: var(--kemu-color-success);
} */

.CloudIcon svg{
	width: 30px;
	height: 30px;
}

.Labels {
	font-size: 14px;
}

.EnvironmentSelection {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.EnvDescription {
	line-height: 1.2em;
	color: #6c757d;
	margin-top: 5px;
}

.DropIcon {
	font-size: 14px !important;
	margin-left: 5px;
}

.DropdownWrapper {
	display: flex;
	flex-direction: row;
  align-items: center;
	cursor: pointer;
	color: var(--kemu-color-primary);
}

.SwitchContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 10px;
}

.Header:focus {
	background-color: inherit;
	outline: none;
}

.Header[contenteditable="false"] {
	cursor: pointer;
	user-select: none;
}