:root {
	--script-widget-btn-color: rgb(220, 220, 220);
}

.GateBody {
	display: flex;
	width: 70px;
	min-height: 32px;
	flex-direction: column;
	background-color: white;
	padding: 5px 0px;
	height: 60px;
	align-items: center;
}

.BugIconContainer {
	position: absolute;
	left: 3px;
	bottom: 0;
}

.BugIcon {
	color: var(--kemu-color-danger);
	font-size: 13px;
}

.ConsoleContainer { 
	position: absolute;
	bottom: 0;
	height: 150px;
	width: 100%;
	background-color: #181915;
	z-index: 6;
	left: 0px;
	border-top: solid 2px #8e8f88;
}

.ConsoleIconsContainer {
	width: 100%;
	height: 30px;
	background-color: #181915;
	position: relative;
	top: 0px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	border-bottom: 1px solid #8e8f8833;
}

.ConsoleIconsContainer :global(.clear-console-button){
	color: white;
}

.ConsoleLines {
	overflow: auto;
	height: 100%;
	background-color: #181915;
}

.ConsoleLine:global(.error) {
	color: #f57f7f;
  background-color: #290000;
}

.ConsoleLine:global(.warning) {
	color: #fcdd9e;
  background-color: #332b00;
}

.ConsoleLine:global(.log) {
	color: #c6c6c6;
  background-color: transparent;
}

.ConsoleLine:global(.info) {
	color: #40a9ff;
  background-color: #000329;
}

.ConsoleLine :global(.timestamp){
	font-weight: 600;
	color: #8e8f88b0;
}

.ConsoleLine {
	color: white;
	border-bottom: 1px solid #8e8f8833;
	padding-left: 10px;
}

.ConsoleLineText { 
  transition: padding 0.1s, background-color 0.15s;
  transition-delay: 0.3s;
  border-radius: 10px;
	cursor: pointer;
}

.ClickedConsoleLineText { 
	animation: LineClick;
	animation-duration: 0.5s;
	animation-fill-mode: forwards;
}

@keyframes LineClick {
	0% { background-color: transparent; padding: 0px; }
	10% {
		background-color: var(--kemu-color-light-darken-70);
		padding: 1px 5px;
		margin-left: -2.5px;
	}
	100% { background-color: transparent; padding: 0px; }
}

.CanvasIconWrapper {
	/* Under the ports */
	z-index: 300;
}

.CanvasIconWrapper:global(.settings-visible){
	/* Above All gates */
	z-index: 303;
}

.SettingsContainer :global(.settings-close-btn) {
	top: 10px;
  right: 10px;
}

.SettingsContainer {
	width: 600px;
	height: 600px;
	padding: 20px 10px 10px 10px;
	background-color: #2f3129;
	position: relative;
	left: -2px;
	top: -2px;
	border-radius: 4px;
}

.EditorContainer {
	width: 100%;
	/* height: 100%; */
	height: calc(100% - 50px);
}


.Editor {
	width: 100%;
	/* height: 520px; */
	height: 100%;
}

.EditorBar {
	width: 100%;
	height: 40px;
	background-color: transparent;
	margin-bottom: 10px;
}

.EditorBar button{
	color: var(--script-widget-btn-color);
}

.EditorBar button.Button:hover,
.EditorBar button.Button:focus,
.EditorBar button.Button:active{
	color: white !important;
}

.EditorBar button.Button:disabled{
	color: gray !important;
}

.EditorBar .Button.PlayPause{
	color: var(--script-widget-btn-color);
}

.EditorBar :global(.play-pause-button.paused){
	color: var(--kemu-color-warning);
}

/* .EditorBar :global(.console-toggle-button:hover){
	color: white;
} */

.Icon {
	font-size: 50px;
}

.WidgetEnabled {
	color: var(--kemu-color-widget-type-custom);
}


/* .Modal :global(.ant-modal-close:hover){
	color: white;
} */

/* .Modal :global(.ant-modal-close){
	color: gray;;
} */

.Modal :global(.kemu-spin-nested-loading),
.Modal :global(.kemu-spin-container) {
	height: 100%;
}

.Modal {
	width: auto !important;
	height: calc(100% - 40px);
	margin: 10px;
	padding: 0px;
	top: 10px;
}

.Modal :global(div[tabindex="-1"]) {
	height: 100%;
}

.Modal > div {
	height: 100%;
}

.Modal div[class^="kemu-modal-"] {
	height: 100%;
}

.Modal.ConsoleVisible {
	/* Extra 30px (from 40px) from the console's icons bar */
	/* height: calc(100% - 70px); */
}

.ModalContent {
	height: 100%;
}

.SettingsModalBody {
	padding-top: 12px;
	padding-left: 0px;
	padding-right: 0px;
	padding-bottom: 0px;
	background-color: #181915;
}

.InstallDepBtnWrapper {
	position: relative;
}

.InstallingDepIcon {
	position: absolute;
	bottom: 8px;
	right: 5px;
	color: var(--kemu-color-success);
}


.DepErrorIcon {
	color: var(--kemu-color-danger);
	font-size: 13px;
	position: absolute;
	bottom: 8px;
	right: 5px;
	transform: scale(1);
	animation: DepErrorPulse 2s infinite;
}

@keyframes DepErrorPulse {
	0% {
		transform: scale(0.85);
	}

	70% {
		transform: scale(1.2);
	}

	100% {
		transform: scale(0.85);
	}
}

.ModalRoot .ScriptWrapper {
	position: absolute;
}

.ModalRoot button:global(.kemu-modal-close) {
	color: #aaa;
}