.FormGroup{
	display: flex;
  flex-wrap: wrap;
	align-items: flex-start;
}

.FormGroup:global(:not(.mb0)){
	margin-bottom: 25px;
}

.FormGroup:global(.mb1) {
	margin-bottom: .3rem;
}

.FormGroup:global(.mb2) {
	margin-bottom: .6rem;
}

.FormGroup:global(.mb3) {
	margin-bottom: .9rem;
}

.FormGroup:global(.mb4) {
	margin-bottom: 1.2rem;
}

.FormGroup label{
	font-weight: 600;
	color: #34395e;
	font-size: 12px;
	letter-spacing: .5px;
	margin-bottom: 0px;
}

.FormGroup:global(.row){
	flex-direction: row;
}

.FormGroup:global(.column){
	flex-direction: column;
	gap: 4px;
}

.FormGroup:global(.v-align) {
	align-items: center;
}