.canvas-gate {
	position: absolute;
  background-color: #fff;
  border-radius: 4px;
	z-index: 302;
	border: 2px solid;
	transition: box-shadow 0.2s ease-in-out;
	will-change: box-shadow;
}

/* check constants.WIDGET_INVOKED_CLASS */
.canvas-gate.widget-invoked {
	box-shadow: 0px 0px 10px 4px var(--kemu-color-widget-invoked);
}

.canvas-gate.widget-disabled {
	border: 2px solid var(--kemu-color-disabled-widget);
}

.canvas-gate.original-event {
	border: 2px solid var(--kemu-color-original-event-widget); 
}

.canvas-gate.original-event .cg-header{
	background-color: var(--kemu-color-original-event-widget);
}

.jtk-endpoint.gate-port.original-event {
	border: 2px solid var(--kemu-color-original-event-widget); 
}

.canvas-gate .cg-header {
	/* width: 100%; */
	height: 20px;
	line-height: 20px;
	color: #fff;
	text-align: center;
	text-transform: uppercase;
	font-size: 7pt;
	font-weight: bolder;
}

.canvas-gate:not(.widget-disabled):not(.original-event) .cg-header {
	/* background-color: var(--kemu-color-enabled-widget); */
}

.canvas-gate.widget-disabled .cg-header {
	background-color: var(--kemu-color-disabled-widget);
}

.canvas-gate .cg-header.custom-settings{
	display: flex;
  justify-content: space-between;
}

.canvas-gate .cg-header.custom-settings .cg-label{
	width: 100%;
}

.canvas-gate .cg-header.custom-settings.visible .settings-btn{
	display: none;
}

.canvas-gate .cg-header.custom-settings .settings-btn{
	width: 20px;
	line-height: 20px;
	align-self: flex-end;
	cursor: pointer;
	position: absolute;
	right: 0px;
}

.canvas-gate .cg-header.custom-settings .settings-btn:hover:not(:active){
	transform: scale(1.3);
	transition: transform 0.1s ease-in;
	will-change: transform;
}


.canvas-gate .gate-settings-wrapper:not(.custom-size){
	overflow: hidden;
}

.canvas-gate .gate-settings-wrapper.visible{
	display: block;
}

.canvas-gate .gate-settings-wrapper{
	/* On top of gate's contents */
	z-index: 2;
	display: none;
}

.canvas-gate .gate-settings-wrapper .animator{
	width: 100%;
	height: 100%;
}

/* .canvas-gate .gate-settings-wrapper .motion{
	width: 100%;
	height: 100%;
} */


.canvas-gate .cg-body {
	/* height: 60px; */
	width: 100%;
	/* top: 30px; */
	/* background-color: red; */
	
	display: flex;
	align-items: center;
	/* height: 30px; */
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	background-color: #fff;
}

/* For gates that change size dynamically */
.canvas-gate .cg-dynamic-body{
	display: flex;
	align-items: center;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	background-color: #fff;
}



.canvas-gate .cg-container .port .label{
	line-height: 17px;
	color: #888;
	position: relative;
	width: auto;
	top: -2px;

}


.canvas-gate .cg-container .in-ports-container .port {
	text-align: right;
	/* Based on the width of the label */
	transform: translateX(-100%);
	padding-right: 20px;
	/* Above ports and connections */
	z-index: 301;
}

/* .canvas-gate .cg-container .in-ports-container .port:hover{
	transform: scale(1.2) translateX(-85%);
	transition: transform 0.1s ease-in-out;
} */

.canvas-gate .cg-container .out-ports-container .port {
	text-align: left;
	left: 30px;
	padding-left: 10px;	
}

.canvas-gate .cg-container .in-ports-container {
	/* position: fixed; */
	position: absolute;
	left: 0px;
	/* overflow: hidden; */
	width: 20px;
	height: 100%;
	top: 0px;
	z-index: -1;
}

.canvas-gate .cg-container .out-ports-container {
	position: absolute;
	right: 0px;
	width: 20px;
	height: 100%;
	top: 0px;
	z-index: -1;
}

.canvas-gate .cg-container .port {
	position: absolute;
}

/* the actual port used by jsplumb */
.jtk-endpoint.gate-port {
	width: 17px;
	height: 17px;
	border-radius: 50%;
	position: absolute;
	left: 0px;
	background-color: rgb(255, 254, 254);
	border: solid 2px var(--kemu-color-enabled-widget);
	z-index: 302;
	/* Back to normal size slow */
	-webkit-transition: transform 0.2s ease-in-out;
	-moz-transition:transform 0.2s ease-in-out;
	-ms-transition:transform 0.2s ease-in-out;
	transition:transform 0.2s ease-in-out;

	cursor: pointer;
}

.jtk-endpoint.gate-port.widget-disabled,
.jtk-endpoint.gate-port.service-offline {
	border: solid 2px var(--kemu-color-disabled-widget);
}

/*  Grow source port on hover */
.jtk-endpoint.gate-port.source-port:not(.endpointDrag):hover {
	transform: scale(2) translateX(7px);

	-webkit-transition: transform 0.2s ease-in-out;
	-moz-transition:transform 0.2s ease-in-out;
	-ms-transition:transform 0.2s ease-in-out;
	transition:transform 0.2s ease-in-out;
}

/* Add a transparent area to the source port to make it easy to click on */
/* .jtk-endpoint.gate-port.source-port:not(.jtk-endpoint-connected)::before {
	width: 12px;
	height: 18px;
	border-radius: 50%;
	transform: translate(2px, -4px) scale(2);
	display: block;
	content: '';
} */

/* makes target ports grow when ready to drop */
.jtk-endpoint.gate-port.target-port.jtk-drag-active.jtk-drag-hover {
	transform: scale(2) translateX(-4px);

	-webkit-transition: transform 0.2s ease-in-out;
	-moz-transition:transform 0.2s ease-in-out;
	-ms-transition:transform 0.2s ease-in-out;
	transition:transform 0.2s ease-in-out;
}

.jtk-endpoint.gate-port.hidden{
	visibility: hidden;
}

/* Left ports */
.jtk-endpoint.gate-port.target-port{
	transform: translateX(-6px);
}

/* Right ports */
.jtk-endpoint.gate-port.source-port{
	transform: translateX(6px);
}


.canvas-gate .cg-container .port .label.break-text{
	white-space: normal;
}

.canvas-gate .cg-container .port .label{
	line-height: 17px;
	color: var(--kc-canvas-gate-port-label);
	position: relative;
	width: auto;
	/* min-width: 60px; */
	top: -2px;
	/* Above ports and connections */
	z-index: 302;
	white-space: nowrap;
}

.custom-widget .lm-canvas-container .canvas-gate .cg-container .port .label {
	color: var(--kc-custom-widget-mode-canvas-gate-port-label);
}

.port .label.hidden {
	display: none;
}

/* Standard gate types */
.canvas-gate .lm-gate-horizontal {
	min-width: 150px;
}

.canvas-gate .lm-gate-horizontal .gate-dropdown {
	/* width: 115px; */
	/* width: auto; */
	line-height: 41px;
	padding-left: 12px;
}

.canvas-gate .lm-gate-horizontal .gate-dropdown:not(:first-child) {
	float: right;
}

/* Action gates like */
/* .canvas-gate .lm-gate-horizontal .gate-dropdown:first-child {
	float: left;
	padding-right: 10px;
} */

/* Action gates like */
/* .canvas-gate .lm-gate-horizontal .gate-dropdown:first-child span[aria-label='down']{
	padding-left: 5px;
} */

/* Input gates like */
.canvas-gate .lm-gate-horizontal .gate-dropdown:not(:first-child) span[aria-label='down']{
	padding-left: 5px;
	padding-right: 10px;
}

.canvas-gate .lm-gate-horizontal .gate-icon {
	width: 35px;
	height: 41px;
	color: #fff;
	text-align: center;
	line-height: 41px;
	float: left;
}

.canvas-gate:not(.widget-disabled) .lm-gate-horizontal .gate-icon {
	/* background-color: var(--kemu-color-enabled-widget); */
}

.canvas-gate.widget-disabled .lm-gate-horizontal .gate-icon {
	background-color: var(--kemu-color-disabled-widget);
}

.canvas-gate .lm-gate-horizontal .gate-icon:not(:first-child) {
	float: right;
	/* margin-left: 10px; */
}

/* Wider gates */
.canvas-gate .is-wider {
	width: 150px;
}

.canvas-gate .is-narrow {
	width: 55px;
}

.icon-inverted {
	filter: invert(1);
}


.canvas-gate .gate-input input {
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
	color: #666;
	font-size: 14px;
	font-weight: 400;

	width: 100%;
	height: 100%;
	padding: 4px;
	text-align: center;
	border: none;
	outline: none;
}

.canvas-gate .cg-body .standard-body {
	width: 100px;
	height: 30px;
}

/* .canvas-gate.widget-selected{
	box-shadow: 0px 0px 7px 1px var(--kemu-color-primary-darken-30);
} */

/* =============== Logic operations: Suspend, timer, Object ==============*/
.canvas-gate:not(.widget-disabled, .original-event).ctgry-logic,
.gate-port.ctgry-logic { border-color: var(--kemu-color-widget-type-logic); }
.canvas-gate:not(.widget-disabled).ctgry-logic .cg-header,
.canvas-gate.ctgry-logic .gate-icon { background-color: var(--kemu-color-widget-type-logic); }
.canvas-gate.ctgry-logic.widget-selected{
	box-shadow: 0px 0px 7px 1px var(--kemu-color-widget-type-logic);
}
/* Settings Container */
.canvas-gate.ctgry-logic .settings-container {
	background-color: var(--kemu-color-widget-type-logic-settings-container);
	border: 2px solid var(--kemu-color-widget-type-logic-settings-border);
}
.canvas-gate.ctgry-logic .settings-container input,
.canvas-gate.ctgry-logic .settings-container .is-input {
	background-color: var(--kemu-color-widget-type-logic-settings-input);
	color: white !important;
}
/* ======================================================================== */


/* ======================== Conditions and Switches =======================*/
.canvas-gate:not(.widget-disabled, .original-event).ctgry-condition,
.gate-port.ctgry-condition { border-color: var(--kemu-color-widget-type-condition); }
.canvas-gate:not(.widget-disabled).ctgry-condition .cg-header,
.canvas-gate.ctgry-condition .gate-icon { background-color: var(--kemu-color-widget-type-condition); }
.canvas-gate.ctgry-condition.widget-selected {
	box-shadow: 0px 0px 7px 1px var(--kemu-color-widget-type-condition);
}
.canvas-gate.ctgry-condition .cg-body button:hover,
.canvas-gate.ctgry-condition .cg-body .kemu-input-suffix,
.canvas-gate.ctgry-condition .cg-body button:focus {
	color: var(--kemu-color-widget-type-condition);
}
/* Settings Container */
.canvas-gate.ctgry-condition .settings-container {
	background-color: var(--kemu-color-widget-type-condition-settings-container);
	border: 2px solid var(--kemu-color-widget-type-condition-settings-border);
}
.canvas-gate.ctgry-condition .settings-container input,
.canvas-gate.ctgry-condition .settings-container .is-input {
	background-color: var(--kemu-color-widget-type-condition-settings-input);
	color: white !important;
}
/* ======================================================================== */

/* ============================= Produce data ============================= */
.canvas-gate:not(.widget-disabled, .original-event).ctgry-producer,
.gate-port.ctgry-producer { border-color: var(--kemu-color-widget-type-producer); }
.canvas-gate:not(.widget-disabled).ctgry-producer .cg-header,
.canvas-gate.ctgry-producer .gate-icon { background-color: var(--kemu-color-widget-type-producer); }
.canvas-gate.ctgry-producer.widget-selected{
	box-shadow: 0px 0px 7px 1px var(--kemu-color-widget-type-producer);
}
.canvas-gate.ctgry-producer .cg-body button:hover,
.canvas-gate.ctgry-producer .cg-body .kemu-input-suffix,
.canvas-gate.ctgry-producer .cg-body button:focus {
	color: var(--kemu-color-widget-type-producer);
}
/* Settings Container */
.canvas-gate.ctgry-producer .settings-container {
	background-color: var(--kemu-color-widget-type-producer-settings-container);
	border: 2px solid var(--kemu-color-widget-type-producer-settings-border);
}
.canvas-gate.ctgry-producer .settings-container input,
.canvas-gate.ctgry-producer .settings-container .is-input {
	background-color: var(--kemu-color-widget-type-producer-settings-input);
	color: white !important;
}
/* ======================================================================== */

/* ============================ Execute actions =========================== */
.canvas-gate:not(.widget-disabled, .original-event).ctgry-consumer,
.gate-port.ctgry-consumer { border-color: var(--kemu-color-widget-type-consumer); }
.canvas-gate:not(.widget-disabled).ctgry-consumer .cg-header,
.canvas-gate.ctgry-consumer .gate-icon { background-color: var(--kemu-color-widget-type-consumer); }
.canvas-gate.ctgry-consumer.widget-selected{
	box-shadow: 0px 0px 7px 1px var(--kemu-color-widget-type-consumer);
}
.canvas-gate.ctgry-consumer .cg-body button:hover,
.canvas-gate.ctgry-consumer .cg-body .kemu-input-suffix,
.canvas-gate.ctgry-consumer .cg-body button:focus {
	color: var(--kemu-color-widget-type-consumer);
}
/* Settings Container */
.canvas-gate.ctgry-consumer .settings-container {
	background-color: var(--kemu-color-widget-type-consumer-settings-container);
	border: 2px solid var(--kemu-color-widget-type-consumer-settings-border);
}
.canvas-gate.ctgry-consumer .settings-container input,
.canvas-gate.ctgry-consumer .settings-container .is-input {
	background-color: var(--kemu-color-widget-type-consumer-settings-input);
	color: white !important;
}
/* ======================================================================== */


/* ====================== Holds data, eg: Text, Value ===================== */
.canvas-gate:not(.widget-disabled, .original-event).ctgry-storage,
.gate-port.ctgry-storage { border-color: var(--kemu-color-widget-type-storage); }
.canvas-gate:not(.widget-disabled).ctgry-storage .cg-header,
.canvas-gate.ctgry-storage .gate-icon { background-color: var(--kemu-color-widget-type-storage); }
.canvas-gate.ctgry-storage.widget-selected{
	box-shadow: 0px 0px 7px 1px var(--kemu-color-widget-type-storage);
}
.canvas-gate.ctgry-storage .cg-body button:hover,
.canvas-gate.ctgry-storage .cg-body .kemu-input-suffix,
.canvas-gate.ctgry-storage .cg-body button:focus {
	color: var(--kemu-color-widget-type-storage);
}
/* Settings Container */
.canvas-gate.ctgry-storage .settings-container {
	background-color: var(--kemu-color-widget-type-storage-settings-container);
	border: 2px solid var(--kemu-color-widget-type-storage-settings-border);
}
.canvas-gate.ctgry-storage .settings-container input,
.canvas-gate.ctgry-storage .settings-container .is-input {
	background-color: var(--kemu-color-widget-type-storage-settings-input);
	color: white !important;
}
/* ======================================================================== */

/* ========= Performs transformations (multiplication, sum, etc) ========== */
.canvas-gate:not(.widget-disabled, .original-event).ctgry-transformer, 
.gate-port.ctgry-transformer { border-color: var(--kemu-color-widget-type-transformer); }
.canvas-gate:not(.widget-disabled).ctgry-transformer .cg-header,
.canvas-gate.ctgry-transformer .gate-icon { background-color: var(--kemu-color-widget-type-transformer); }
.canvas-gate.ctgry-transformer.widget-selected{
	box-shadow: 0px 0px 7px 1px var(--kemu-color-widget-type-transformer);
}
.canvas-gate.ctgry-transformer .cg-body button:hover,
.canvas-gate.ctgry-transformer .cg-body .kemu-input-suffix,
.canvas-gate.ctgry-transformer .cg-body button:focus {
	color: var(--kemu-color-widget-type-transformer);
}
/* Settings Container */
.canvas-gate.ctgry-transformer .settings-container {
	background-color: var(--kemu-color-widget-type-transformer-settings-container);
	border: 2px solid var(--kemu-color-widget-type-transformer-settings-border);
}
.canvas-gate.ctgry-transformer .settings-container input,
.canvas-gate.ctgry-transformer .settings-container .is-input {
	background-color: var(--kemu-color-widget-type-transformer-settings-input);
	color: white;
}

.canvas-gate.ctgry-transformer .settings-container input:disabled {
	opacity: 0.6;
}

/* ======================================================================== */

/* ========= Custom widgets ========== */
.canvas-gate.ctgry-custom:not(.widget-selected):not(.widget-invoked){
	box-shadow: none !important;
}

.canvas-gate:not(.widget-disabled, .original-event).ctgry-custom, 
.gate-port.ctgry-custom { border-color: var(--kemu-color-widget-type-custom); }
.canvas-gate:not(.widget-disabled).ctgry-custom .cg-header,
.canvas-gate.ctgry-custom .gate-icon { background-color: var(--kemu-color-widget-type-custom); }

/* Custom hub services with ui define their own effects */
.canvas-gate.ctgry-custom.widget-selected:not(.custom-hs-ui){
	box-shadow: 0px 0px 7px 1px var(--kemu-color-widget-type-custom);
}
/* .canvas-gate.ctgry-custom .cg-body button:hover,
.canvas-gate.ctgry-custom .cg-body .kemu-input-suffix,
.canvas-gate.ctgry-custom .cg-body button:focus {
	color: var(--kemu-color-widget-type-custom);
} */
/* Settings Container */
.canvas-gate.ctgry-custom .settings-container {
	background-color: var(--kemu-color-widget-type-custom-settings-container);
	border: 2px solid var(--kemu-color-widget-type-custom-settings-border);
}
.canvas-gate.ctgry-custom .settings-container input:not(.no-glo-rls):not([type="password"]),
.canvas-gate.ctgry-custom .settings-container .is-input {
	background-color: var(--kemu-color-widget-type-custom-settings-input);
	color: white !important;
}

svg.widget-connector {
	z-index: 300;
	stroke: #88cdff;
	stroke-dasharray: 10, 2;
	/* animation: BiDashSegmentAnimation 1s linear infinite; */
}

svg.widget-connector.jtk-dragging {
  /* stroke-dasharray: 10, 2; */
  animation: BiDashSegmentAnimation 1s linear infinite;
}

svg.widget-connector.jtk-hover path:nth-child(1),
svg.widget-connector.jtk-dragging path:nth-child(1) {
	/* stroke: #88cdff; */
	stroke: var(--kemu-color-primary-lighten-20);
	stroke-width: 6px;
}

svg.widget-connector path:nth-child(1){
	stroke-width: 4px;
	stroke: var(--kemu-color-gray);
}

svg.widget-connector.widget-invoked path:nth-child(1){
	stroke: var(--kemu-color-secondary-lighten-30);
	animation: BiDashSegmentAnimation 1s linear infinite;
	will-change: stroke-dashoffset;
}

svg.widget-connector:not(.jtk-dragging) .jtk-overlay{
	display: none;
}

.cg-body.hs-container {
	flex-direction: column;
}