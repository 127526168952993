.GateBody {
	display: flex;
  width: 62px;
  height: 40px;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	background-color: var(--kemu-color-widget-type-transformer);
	color: white;
	padding-bottom: 1px;
}

.DisabledWidget {
	background-color: var(--kemu-color-disabled-widget);
}

.LargeGateBody {
	height: 62px;
	justify-content: space-evenly;
	padding-bottom: 10px;
}

.GateBody svg {
	width: 42px;
	height: 42px;
}

/* Force settings container above ports when a port is dynamically added */
:global(.image-crop-widget-wrapper .cg-container .cg-header) {
	padding-right: 10px;
}

:global(.image-crop-widget-wrapper.settings-visible) {
	z-index: 303;
}

.SettingsContainer {
	width: 135px;
	min-height: 90px;
	padding: 20px 10px 10px 10px;
	position: relative;
	left: -2px;
	top: -2px;

	height: calc(100% + 4px);
}

.SettingsContainer label{
	color: white;
}


.InputContainer{
	margin-top: 10px;
	display: flex;
	flex-direction: column;
}


.SingleRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.InputColumn {
	display: flex;
	flex-direction: column;
}

.InputContainer :global(input) {
	border-bottom-left-radius: 0px !important;
	border-bottom-right-radius: 0px !important;
	border-top-left-radius: 0px !important;
	border-top-right-radius: 0px !important;
	text-align: center;
}


.SwitchContainer {
	display: flex;
	flex-direction: column;
	align-items: baseline;
	padding-top: 10px;
}

.InputColumn label {
	text-align: center;
}

.InputWrapper :global(.ant-input-number-handler-wrap){
	display: none;
}

.SettingsContainer.ManualMode {
	width: fit-content;
	height: fit-content;
}

.CroppingContainer {
	margin-top: 10px;
}

.CroppingContainer canvas {
	background-color: black;
}

.CroppingContainer .NoImage {
	width: 300px;
	height: 230px;
	background-color: black;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
}