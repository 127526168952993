.SubmitButton{
	width: 100%;
}

.CardBottomText{
	color: #98a6ad;
	text-align: center;
}

.SubmitContainer{
	text-align: center;
}