.Wrapper :global(.cg-header) {
	padding-right: 10px;
}

div.CanvasBody {
	width: 80px;
	height: 50px;
}

.SettingsContainer {
	min-width: 270px;
	padding: 30px 1px 0px 1px;
	position: relative;
	transform: translate(-30%, -10%);
}

.DropdownContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 4px;
	padding: 2px;
}

.Dropdown {
	width: 100%;
}

.SettingsContainer label{
	color: white;
}

.FiltersContainer {
	padding: 3px;
	margin-top: 3px;
	min-height: 75px;
	background-color: white;
	display: flex;
	flex-direction: column;
	gap: 4px;
	position: relative;
}

.DropValue {
	width: 100px;
}

.FiltersContainer div {
	line-height: 24px;
}


button.AddBtn {
	width: 48px !important;
}