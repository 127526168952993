.WidgetBody {
	display: flex;
  width: 62px;
  height: 40px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: white;
	padding-bottom: 1px;
}

:global(.canvas-gate.ctgry-transformer) .WidgetBody {
  background-color: var(--kemu-color-widget-type-transformer);
}

:global(.canvas-gate.ctgry-storage) .WidgetBody {
  background-color: var(--kemu-color-widget-type-storage);
}

:global(.canvas-gate.ctgry-consumer) .WidgetBody {
  background-color: var(--kemu-color-widget-type-consumer);
}

:global(.canvas-gate.ctgry-producer) .WidgetBody {
  background-color: var(--kemu-color-widget-type-producer);
}

:global(.canvas-gate.ctgry-condition) .WidgetBody {
  background-color: var(--kemu-color-widget-type-condition);
}

:global(.canvas-gate.ctgry-custom) .WidgetBody {
  background-color: var(--kemu-color-widget-type-custom);
}

:global(.canvas-gate.ctgry-logic) .WidgetBody {
  background-color: var(--kemu-color-widget-type-logic);
}


.LargeWidgetBody {
	height: 62px;
	justify-content: space-evenly;
	padding-bottom: 10px;
}

.WidgetBody svg {
	width: 38px;
	height: 38px;
}
