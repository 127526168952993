.GateBody {
    display: flex;
    width: 100px;
}

.Counter {
    background-color: #e9e9e9;
    color: #757575;
    padding: 0 6px 0 6px;
    min-width: 40px;
    text-align: center;
    line-height: 30px;
    font-weight: bold;
}