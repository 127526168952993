

.ExpandedContainer {
	width: 100%;
	/* position: relative; */
	min-height: 100px;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	background-color: #e5e5e5;
	border-radius: 4px;
	animation: subFolder 0.20s forwards;
	position: absolute;
	overflow: hidden;
	z-index: 1;
}

.ExpandedContainer.FirstRender {
	animation: unset;
}

@keyframes subFolder {
	from {
		opacity: 0;
		transform: scale(0.5)
	}
	to {
		opacity: 1;
		transform: scale(1.0);
	}
}

.CloseBtn {
	position: absolute;
	top: 5px;
	right: 5px;
	color: #18212d;
}

button.CloseBtn:hover {
	color: #3d4a5c !important;
}

.ExpandedContainer .TopBar {
	height: 25px;
	color: white;
	text-align: center;
	height: 25px;
	color: #343c47;
	text-align: center;
	margin-right: 30px;
	padding-left: 30px;
	line-height: 25px;
	font-size: 14px;
}


/* .ServiceButton :global(.logic-gate-icon) span.anticon{
	font-size: 30px;
} */


.SubWidgetsGrid {
	width: 100%;
	height: 100%;
	display: flex;
	flex-wrap: wrap;
	overflow: auto;
	align-content: flex-start;
	justify-content: flex-start;
	padding: 15px 20px 50px 10px;
	gap: 30px 0px;
	position: relative;
}

.FolderPlaceholder svg {
	transition: transform 0.35s ease-in-out 100ms;
	will-change: transform;
}

.FolderIconContainer {
	height: 50px;
  width: 70px;
	display: flex;
	border-radius: 10px;
}

.FolderPlaceholder:hover svg {
	transform: scale(1.2);
	-webkit-font-smoothing: subpixel-antialiased;
}

.FolderPlaceholder {
	border-radius: 10px;
	position: relative;
	text-align: center;
	width: 75px;
	cursor: pointer;
	transition: transform 0.35s;
	transition-delay: 100ms;
	will-change: transform;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding: 5px 0px;
	margin: 6px;
	height: 100px;
}

.FolderPlaceholder svg {
	width: 70px;
	height: 70px;
}

.ServiceName {
	margin-top: 7px;
	white-space: normal;
	margin-bottom: 3px;
	font-weight: 600;
	color: var(--widget-launchpad-button-text-color, gray);
	position: relative;
	line-height: normal;
	text-align: center;
}
