
.ServiceButton {
	border-radius: 10px;
	position: relative;
	text-align: center;
	width: 75px;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding: 5px 0px;
	margin: 6px;
	height: 100px;
}


.ServiceButton:hover {
	z-index: 100;
}

.ServiceName {
	white-space: normal;
	margin-top: 7px;
	margin-bottom: 3px;
	font-weight: 600;
	color: var(--widget-launchpad-button-text-color, gray);
	position: relative;
	line-height: normal;
}

.FooterDescription {
	width: 100%;
}

.Link {
	padding: 0px;
}
