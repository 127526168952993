.Accordion :global(.ant-collapse-content-box){
	padding: 0px;
}

.Accordion :global(.ant-collapse-header){
	padding-left: 25px !important;
}

.Accordion :global(.ant-collapse-header .ant-collapse-arrow){
	left: 0px !important;
}

.ModalBody {
	max-height: 60vh;
  overflow: auto;
}

.ModalRoot {
	transition: width 0.2s ease-in-out;
}

.ColorContainer {
	width: 100px;
	/* display: flex;
	flex-direction: column; */
}


.ColorAndIconRow {
	display: flex;
	margin-top: 15px;
	justify-content: space-between;
	width: 160px;
	align-items: center;
}

.Swatch {
	margin-top: 6px;
	width: 50px;
	display: block;
}

.SwatchPopup{
	margin-top: 12px;
	padding-bottom: 30px;
}

.TabsContainer {
	max-height: 480px;
	overflow: auto;
	position: relative;
	padding: 5px;
}