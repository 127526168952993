.HeaderName {
	margin-left: 14px;
	margin-right: 14px;
}

.PanelHeader svg {
	fill: var(--widget-launchpad-section-header-color);
}

.Panel {
	border-bottom: 1px solid var(--widget-launchpad-border-color) !important;
}

.Panel:last-child {
	border-bottom: unset !important;
}

.Panel :global(.ant-collapse-content-box){
	padding: 0px;
	background-color: inherit;
}

.Panel :global(.ant-collapse-content){
	background-color: inherit;
	border: none;
}

.Panel:global(.ant-collapse-item-active .ant-collapse-header){
	box-shadow: 0px 0px 5px 2px rgb(0 0 0 / 10%);
	border-bottom: unset;
	border-radius: 0px !important;
}



.Panel :global(.kemu-collapse-content-box) {
	height: 50vh;
}