.CompactInput {
	height: 32px;
}

.GeneratingContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.GeneratingContainer :global(.kemu-spin-nested-loading) {
	width: 100%;
}

.ModeGroup {
	display: flex;
	flex-direction: column
	
}

.Content {
	margin-bottom: 50px;
}
.ModeDescription {
	/* margin-top: 0px; */
}

.ModeDropdown {
	margin-bottom: 8px;
}