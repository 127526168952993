/* 
.canvas-container,
.lm-canvas-container {
	width: 100%;
	height: 100%;
	overflow: hidden;
} */

.CanvasContainer {
	width: 100%;
	height: 100%;
}

/* .CanvasContainer :global(.canvas-background) {
	width: 300%;
	height: 300%;
	background-size: 20px 20px;
	user-select: none;
	
	background-color: var(--kc-canvas-bg);	
	transition: background-color 0.3s ease;
	border-top-left-radius: 12px;
}

.CanvasContainer :global(.canvas-background:not(.no-grid)) {
	background-image:
    linear-gradient(to right, var(--kc-canvas-grid-color) 1px, transparent 1px),
		linear-gradient(to bottom, var(--kc-canvas-grid-color) 1px, transparent 1px);
}
 */
/* .dark-theme .canvas-background {
	width: 300%;
	height: 300%;
	background-size: 20px 20px;;
	background-image:
    linear-gradient(to right, #ffffff24 1px, transparent 1px),
		linear-gradient(to bottom, #ffffff24 1px, transparent 1px);
	background-color: #38424b;	
} */

:global(.custom-widget) .BgPattern {
	background-color: var(--kc-custom-widget-mode-canvas-bg);
	transition: background-color 0.3s ease;
}

:global(.custom-widget) .BgPattern circle {
	fill: #a5b1bb;
}

.Viewer {
	/* height: 100vh;
	width: 100vw; */
	/* height: 2000px; */
	width: 100%;
	height: 100%;
}

.Viewport {
	/* position: relative;
	z-index: 0;
  display: block; */
	/* width: 100%;
	height: 100%; */
	position: relative;
	/* border: 1px solid green; */
	width: 100%;
	height: 100%;
}

.BgPattern {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: var(--kc-canvas-bg);	
}

/* .Viewport :global(.canvas-background) {
	width: 300%;
	height: 300%;
	background-size: 20px 20px;
	user-select: none;
	
	background-color: var(--kc-canvas-bg);	
	transition: background-color 0.3s ease;

	border-top-left-radius: 12px;
}

.Viewport :global(.canvas-background:not(.no-grid)) {
	background-image:
    linear-gradient(to right, var(--kc-canvas-grid-color) 1px, transparent 1px),
		linear-gradient(to bottom, var(--kc-canvas-grid-color) 1px, transparent 1px);
} */