.Input {
  border-radius: 25px;
  max-width: 300px;
  font-size: 14px;
	padding: 10px 15px;

	/* background-color: #fdfdff; */
	border-color: #e4e6fc;
	width: 100%;
	
	font-weight: 400;
	line-height: 1.5;
	color: #495057;

	background-clip: padding-box;
	border: 1px solid #ced4da;

	transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
	outline-offset: -2px;

  /* margin-left: auto; */

  margin: 7px;
  border: none;
  background-color: #f0f0f0;
	border: 1px solid #e4e6fc;
}


.Input :global(.kemu-input-suffix){
  color: var(--kemu-color-primary);
  font-weight: 700;
}