.GroupHeader {
  color: #fff;
  padding: 0px;
  padding-left: 8px; 
  padding-right: 8px;
  position: relative;
}

.GroupHeader.HasGearIcon {
  padding-right: 14px;
}

.Title {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  text-transform: uppercase;
  font-size: 7pt;
  font-weight: bolder;
  line-height: 20px;
  text-overflow: ellipsis;
}

.DarkHeader:not(.DisabledWidget) {
  color: var(--kc-canvas-gate-port-label);
}

.GearBtnWrap {
	width: 10px;
	line-height: 20px;
  font-size: 7pt;
	cursor: pointer;
	position: absolute;
	right: 5px;
  top: 0px;
  text-align: center;
}


.GearBtnWrap:hover:not(:active){
	transform: scale(1.3);
	transition: transform 0.1s ease-in;
	will-change: transform;
}