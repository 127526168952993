
.TypesMenu {
	color: #60686f;
	font-size: 10px;
}

.InputWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  display: flex;
	width: 128px;
}

.InputWrapper input {
  text-align: center;
}

.ChooseType {
	height: 100%;
	background-color: #fff;
	color: var(--kemu-color-secondary);
	width: 25px;
	line-height: 20px;
	text-align: center;
	cursor: pointer;
  margin-right: 2px;
	font-size: 20px;
}

/* Style fix when only 1 input box is visible */
.ChooseType:global(.type-3),
.ChooseType:global(.type-5) {
	width: 35px;
}

/* ImageData */
.ChooseType:global(.type-7) {
	font-size: 13px;
	width: 25px;
	padding: 0px 5px;
}

.ChooseType:global(.type-0) {
	font-size: 15px;
}