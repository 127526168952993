.GateBody {
	display: flex;
	width: 110px !important;
}

.SettingsContainer {
	width: calc(100% + 10px);
	padding: 20px 10px 10px 10px;
	
  transform: translate(-5px, -2px);
}

.SettingsContainer label{
	color: white;
}

.Column {
	display: flex;
	flex-direction: row;
}

.Column :global(.gate-input input){
	border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
	color: white;
	background-color: var(--kemu-color-widget-type-transformer-settings-input);
}

.Column :global(.gate-input input)::placeholder{
	color: white;
	opacity: 0.5;
}