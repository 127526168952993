.NumberInput {
  width: 100%;

}

.NumberInput input:focus {
  background-color: #fefeff;
  border-color: #95a0f4;
  color: #495057;
  box-shadow: none !important;
  outline: 0;
}

.NumberInput input {
  width: 100%;
  font-size: 14px;
  padding: 10px 15px;
  height: 42px;
  background-color: #fdfdff;
  border-color: #e4e6fc;
  display: block;
  width: 100%;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  outline-offset: -2px;
}