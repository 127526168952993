.Modal {
	display: flex;
	flex-direction: column;
	/* background-color: #fff;
  background-clip: padding-box; */
}

.Header {
	border-bottom: 0;
	padding: 12px 25px 5px 25px;
	display: flex;
	-webkit-box-align: start;
	align-items: flex-start;
	-webkit-box-pack: justify;
	justify-content: space-between;
	/* border-top-left-radius: .3rem;
  border-top-right-radius: .3rem; */
}

.Title {
	font-size: 18px;
	margin-bottom: 0;
	line-height: 1.5;
	font-weight: 700;
	font-family: 'Nunito','Segoe UI',arial;
	color: #6c757d;
	
}

.Body {
	padding: 25px;
	padding-top: 15px;
	position: relative;
	-webkit-box-flex: 1;
	flex: 1 1 auto;
}

.Footer:global(:not(.transparent)) {
	background-color: #f7f9f9 !important;
}

.Footer {
	border-top: 0;
	padding: 25px;
	padding-top: 15px;
	padding-bottom: 15px;
	display: flex;
	align-items: center;
	-webkit-box-pack: end;
  justify-content: flex-end;
}

.Footer button{
	min-width: 67px;
}

.ModalWrapper .ModalContent {
	padding: 0px;
	overflow: hidden;
}