.DragPreview {
	border-radius: 10px;
	/* position: relative; */
	text-align: center;
	width: 80px;
	/* cursor: pointer;
	transition: transform 0.35s;
	transition-delay: 100ms; */
	/* will-change: transform; */
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding: 5px 0px;
	margin: 5px 5px;
	height: 100px;
	z-index: 302;
}

.DragPreview :global(.logic-gate-icon) {
	font-size: 25px;
	line-height: 25px;
	color: white;
	background-color: var(--kemu-color-secondary);
	padding: 10px;
	border-radius: 7px;
	width: 60px;
	margin: 0 auto;
	height: 50px;
}

.ServiceButton {
	border-radius: 10px;
	position: relative;
	text-align: center;
	width: 64px;
	height: 64px;
	cursor: pointer;
	transition: transform 0.35s;
	transition-delay: 100ms;
	will-change: transform;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding: 5px 0px;
	margin: 6px;
	transform: translate(-10px, -15px);
}


.ServiceButton .Icon {
	font-size: 25px;
	line-height: 25px;
	color: white;
	padding: 10px;
	border-radius: 7px;
	width: 60px;
	height: 50px;
	margin: 0 auto;
}