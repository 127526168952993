.Label{
	font-weight: 600;
	color: #34395e;
	font-size: 12px;
	letter-spacing: .5px;

	display: inline-block;
  margin-bottom: .5rem;
}

.Help {
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	gap: 5px;
}
