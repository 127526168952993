.SubmitButton{
	width: 100%;
}

.CardBottomText{
	color: #98a6ad;
	text-align: center;
}

.SubmitContainer{
	text-align: center;
}

.ForgotLink{
	/* position: absolute; */
	top: 0px;
	/* right: 0px; */
	float: right;
	margin-left: auto;
	margin-bottom: 20px;
	/* padding-bottom: 5px; */
}

.ForgotLink a{
	font-size: 12px;
  line-height: 20px;
}

.PasswordGroup{
	margin-bottom: 5px;
}