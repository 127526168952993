.GateBody {
	width: 112px;
	/* padding: 0px 5px; */
}

.InnerBody{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
}

.Title {
	font-weight: 900;
}

.Spacer {
	width: 100%;
	height: 10px;
}

.SettingsContainer {
	width: 116px;
	height: calc(100% + 4px);
	min-height: 148px;
	padding: 20px 10px 10px 10px;
	background-color: var(--kemu-color-primary-darken-80);
	position: relative;
	left: -2px;
	top: -2px;

	/* Simulate gate border */
	border: 2px solid var(--kemu-color-primary-lighten-10);
}

.SettingsContainer label{
	color: white;
}

.SettingsContainer input {
	background-color: #716f91;
	color: white !important;
}


.InputContainer{
	margin-top: 15px;
	display: flex;
	flex-direction: column;
}


.SwitchContainer{
	display: flex;
	flex-direction: column;
	align-items: baseline;
	padding-top: 10px;
}