.PaginationWrapper {
  margin-bottom: 20px;
  margin-right: 40px;
  justify-content: flex-end;
}

.RecipeList {
  margin-top: 20px;
  max-width: 80%;
  display: flex;
  flex-direction: column;
  /* background-color: white; */
  padding: 20px;
  margin: auto;
  height: 80%;
  max-width: 1200px;
}

.KemuRow {
  align-content: flex-start;
  width: 100%;
}

.NoResults {
  text-align: center;
  padding: 40px 0;
}