.GateBody {
	display: flex;
	width: 128px;
	/* padding: 0px 5px; */
	flex-direction: column;
}

.SettingsContainer {
	width: 132px;
	min-height: 146px;
	padding: 20px 10px 10px 10px;
	background-color: var(--kemu-color-primary-darken-80);
	position: relative;
	left: -2px;
	top: -2px;

	height: calc(100% + 4px);
	/* Simulate gate border */
	border: 2px solid var(--kemu-color-primary-lighten-10);
}

.SettingsContainer label{
	color: white;
}

.SettingsContainer input {
	background-color: #716f91;
	color: white !important;
}


.InputContainer{
	margin-top: 15px;
	display: flex;
	flex-direction: column;
}

.Button {
	padding: 2px 5px;
	margin-bottom: 10px;
}

.ChooseType {
	height: 100%;
	background-color: #fff;
	color: var(--kemu-color-secondary);
	width: 25px;
	line-height: 20px;
	text-align: center;
	cursor: pointer;
	font-size: 20px;
}

/* Style fix when only 1 input box is visible */
.ChooseType:global(.type-3),
.ChooseType:global(.type-5) {
	width: 35px;
}

/* ImageData */
.ChooseType:global(.type-7) {
	font-size: 13px;
	width: 25px;
	padding: 0px 5px;
}

.ChooseType:global(.type-0) {
	font-size: 15px;
}

.TypesMenu {
	color: #60686f;
	font-size: 10px;
}

.SwitchContainer {
	display: flex;
	flex-direction: column;
	align-items: baseline;
	padding-top: 10px;
}

.PropItem {
	display: flex;
	flex-direction: row;
	height: 20px;
	padding-bottom: 1px;
}

.PropItem:not(:last-child){
	border-bottom: 1px solid #f0f0f0;
	margin-bottom: 1px;
	padding-bottom: 2px;
}

.PropItem .gate-input {
	height: 100%;
	width: fit-content;
}

/* .PropItem :global(.gate-input input){

} */

.PropItem :global(.gate-input.name){
	border-right: 1px solid #f0f0f0;
}

.PropItem :global(.gate-input.name) input{
	width: 63px;
}

.PropItem :global(.gate-input.name:not(.has-value)) input{
	width: 100%;
}

.PropItem :global(.gate-input.value) input{
	width: 40px;
}

/* .PropItem :global(.gate-input) input:focus {
	width: 70px;
} */