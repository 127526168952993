.GateBody {
  display: flex;
}

.DropDown, .DropDown:hover {
  border: none;
  border-radius: 0px;
  /* border-right: 2px solid var(--kemu-color-widget-type-condition); */
  border-right-width: 2px;
  border-right-style: solid;
  border-right-color: var(--kemu-color-widget-type-condition);
}

.Input:focus {
  outline: none;
}

.Input {
  width: 70px;
  border: none;
  outline: none;
  text-align: center;
}


.SettingsContainer label{
	color: white;
	padding-bottom: 6px;
}


.SettingsContainer {
	min-height: 95px;
	padding: 20px 10px 10px 10px;
	background-color: var(--kemu-color-primary-darken-80);
	position: relative;
	left: -2px;
	top: -2px;

	height: calc(100% + 4px);
	width: calc(100% + 4px);

	/* Simulate gate border */
	border: 2px solid var(--kemu-color-primary-lighten-10);
}

.SwitchContainer {
	display: flex;
	flex-direction: column;
	align-items: baseline;
	padding-top: 10px;
}