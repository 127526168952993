.GateBody {
	display: flex;
	width: 110px !important;
}

.SettingsContainer {
	width: calc(100% + 10px);
	padding: 20px 10px 10px 10px;
  transform: translate(-5px, -2px);
}

.SettingsContainer label{
	color: white;
}

.Input {
	font-size: 14px;
	font-weight: 400;
	font-family: 'Nunito', 'Segoe UI', arial;
	border-radius: 6px !important;
}


.LabelProp {
	padding: 4px;
	text-align: center;
}

.Row {
	margin-bottom: 5px;
}

.ColorPicker input {
	background-color: white !important;
}