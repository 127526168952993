.GateBody {
	display: flex;
	width: 100px;
}

.SettingsContainer {
	width: 100%;
	height: 100%;
	padding: 20px 10px 10px 10px;
	border-width: 0px !important;
}

.SettingsContainer label{
	color: white;
}