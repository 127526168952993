.Input{
	width: 100%;
}

.Input:global(.kemu-select-focused .kemu-select-selector){
	box-shadow: unset !important;
	-webkit-box-shadow: unset !important;
	border-right-width: unset !important;
}

.Input :global(.kemu-select-selector){
	border: none !important;
	text-align: center;
	border-radius: 0px !important;
	padding: 0px !important;
}