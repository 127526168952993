:root{
	--marketplace-subcategory-height: 56px;
}

.Header{
	display: flex;
	align-items: center;
	justify-content: center;
	padding-left: 10px;
}

.Header :global(.ant-layout .ant-layout-has-sider){
	height: 100vh;
}

.HeaderLogo {
	font-size: 150px;
	color: white;
	margin-right: auto;
}


#SubCategoryBar {
	background-color: #fff;
	margin-top: 0px;
	height: var(--marketplace-subcategory-height);

	position: fixed;
	z-index: 10;
	width: 100%;

	display: flex;
}

#SubCategoryBar .SubCategoryBarBtn {
	height: 100%;
	line-height: var(--marketplace-subcategory-height);
	min-width: 100px;
	text-align: center;
	border: 0px;
	border-radius: 0px;
}

#SubCategoryBar .SubCategoryBarBtn::before {
	background-color: unset;
}


#SubCategoryBar .SelectedSubCategory::after {
	background: var(--kemu-color-primary);
	width: 100%;
	content: '';
	height: 3px;
	position: absolute;
	bottom: 0px;
	left: 0px;
}

.SubCategoryBarBtn:not(.SelectedSubCategory):hover {
	color: #f6f6f6;
	/* background-color: #b8b3ad; */
	background-color: var(--kemu-color-primary-lighten-40);
}

.SectionBody {
	/* margin-left: 10px; */
	/* margin-right: 10px; */
	padding-bottom: 100px;
	min-width: 300px;
	height: 100%;

	overflow-y: auto;
	margin-bottom: 100px;
}

.BackButton {
	position: absolute;
	right: 10px;
	color: white;
}

.SectionHeader {
	padding-top: 65px !important;
}

@media (max-width: 575.98px){
	.SectionHeader{
		padding-left: 50px !important;
	}
}

.Section{
	height: 100vh;
	overflow-x: hidden;

	overflow-y: hidden;
}



.CreateTutorialBtn {
	border-radius: 24px;
	margin-left: 20px;
	/* border: 1px dashed black;
	border-color: black !important; */
	/* color: var(--kemu-color-secondary) !important; */
	/* background-color: white !important; */
}

.CreateTutorialBtn:global(.loading span) {
	padding-left: 7px;
}

.SearchBoxContainer {
  display: inline-block;
	margin-left: auto;
	width: 500px;
	margin-right: 20px;
}