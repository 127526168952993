.ModalBody {
	max-height: 60vh;
  overflow: auto;
}

.ColorContainer {
	width: 100px;
	/* display: flex;
	flex-direction: column; */
}

.ColorAndIconRow {
	display: flex;
	justify-content: space-between;
	width: 160px;
	align-items: center;
  margin-top: -10px;
}

.Swatch {
	/* margin-top: 6px; */
	width: 50px;
	display: block;
}

.SwatchPopup{
	margin-top: 12px;
	padding-bottom: 30px;
}

.Content {
  display: flex;
  flex-direction: column;
  /* Enough space for the color picker to show up */
  min-height: 370px;
}

.FooterWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
}

.LeftFooterBtn {
  margin-right: auto;
}

.FileDropWrapper { 
  margin-top: 10px;
  width: 100%;
  height: 90px;
  border: 1px dashed #a7a4a4;
  background-color: #f5f5f5;
}

.FileDrop {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.FileDrop div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.AssetWrapper {
  margin-top: 10px;
}

.AssetList {
  max-height: 100px;
  overflow: auto;
}

.AntList {
  border-top: unset;
}


.AssetItem {
  width: 100%;
  height: 35px;
  overflow: hidden;
  padding-right: 8px !important;
}

.AssetItemInner {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.ListHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 8px 16px;
  border: 1px solid #d9d9d9;
  padding-right: 8px;
  padding-bottom: 8px;
  justify-content: space-between;
}

.AddAssetBtn {
  color: var(--kemu-color-primary);
}