.SubmitButton{
	width: 100%;
}



.SubmitContainer{
	text-align: center;
}

.CardBottomText button{
	border: none;
	outline: none;
	background-color: transparent;
	color: blue;
	cursor: pointer;
	padding: 0px;
}

.CardBottomText button:focus,
.CardBottomText button:active {
	border: none;
	outline: none;
}