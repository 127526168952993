.Header:focus {
	/* background-color: #fefeff;
	border-color: #95a0f4;
	color: #495057; */
	/* border: 1px dashed green; */
	background-color: #cdd0d2;
	box-shadow: none !important;
	outline: 0;
}

.Header:not(.EditingHeader){
	cursor: pointer;
}

.Header:not(.EditingHeader):hover{
	color: #1692ff
}
/* .Button {
	background-color: unset;
	font-size: 18px;
	border: none;
	cursor: pointer;
} */

.Button:focus {
	outline: 0
}

.HeaderWrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
}