.GateBody {
	display: flex;
	width: 100px;
}

.SettingsContainer {
	width: 123px;
	height: auto;
	background-color: white;
	padding: 20px 10px 10px 10px;
	background-color: var(--kemu-color-primary-darken-80);
	transform: translateX(-9px);
	position: relative;
	left: -2px;
	top: -2px;
}

.SettingsContainer label{
	color: white;
}

.Input{
	width: 100%;
}

.InputContainer{
	margin-top: 15px;
	display: flex;
	flex-direction: column;
}

.Button {
	padding: 2px 5px;
	margin-bottom: 10px;
}