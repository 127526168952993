.Icon {
	transition: transform 0.35s ease-in-out 100ms;
	will-change: transform;
	font-size: 25px;
	line-height: 25px;
	color: white;
	background-color: var(--kemu-color-secondary);
	padding: 10px;
	border-radius: 7px;
	width: 60px;
	margin: 0 auto;
	height: 50px;
}

:global(div:hover) .Icon {
	transform: scale(1.2);
	-webkit-font-smoothing: subpixel-antialiased;
}

:global(div:not(:hover)) .Icon {
	transform: scale(1);
}

.Icon.Selected::after {
  box-shadow: 0px 0px 12px 8px hsl(0deg 0% 0% / 20%);
	opacity: 1;
}

.Icon::after {
	content: '';
	border-radius: 7px;
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	border-width: 3px;
	border-style: solid;
	border-color: white;
	opacity: 0;
	transition: opacity 0.1s ease-in-out;
	will-change: opacity;
	margin-left: -3px;
  margin-top: -3px;
}