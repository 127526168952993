.GateBody {
	display: flex;
	min-height: 60px;
	min-width: 133px;
	background-color: black;
	align-items: center;
}

@font-face {
	font-family: DigitalLCD;
	src: url('../../../assets/fonts/lcdfont/DIGITALDREAM.ttf');
}

.LcdContainer {
	min-height: 50px !important;
	max-width: 360px;
	justify-content: flex-end;
}

.Canvas {
	width: 100%;
	height: 100%;
}

.Lcd{
	/* float: right; */
	font-family: DigitalLCD;
	overflow-wrap: anywhere;
	font-size: 30px;
	color: #faff00;
	padding: 0px 10px;
}

.ResolutionView {
	bottom: 0px;
	right: 0px;
	width: 100%;
	text-align: left;
	padding-left: 5px;
	height: 20px;
	color: white;
	background-color: rgb(0 0 0 / 30%);
	position: absolute;
	opacity: 0;
	transition: opacity 0.15s;
	cursor: pointer;
}

.AlwaysVisible {
	opacity: 1;
}

.GateBody:hover .ResolutionView{
	opacity: 1;
}