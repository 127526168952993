.Container {
	width: 100%;
	height: 100%;
	background-color: rgb(0 0 0 / 65%);
	position: absolute;
	left: 0px;
	top: 0px;
	/* Above the Thing downloads indicator */
	z-index: 1011;
	display: flex;
	justify-content: center;
	align-items: center;
	color: black;
	flex-direction: column;
}

.Message {
	padding-bottom: 10px;
	color: white;
}