
.SwitchGroup {
	gap: 9px;
}

.DropZone {
  width: 100%;
  height: 80px;
  background-color: #fafafa;
  border: 1px dashed black;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.DropZone.DropHover {
  background-color: #c8fab7;
}

.CenteredContent {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.InstallIcon {
  width: 42px;
  height: 42px;
  fill: #525252;
}

.LogsContainer {
  margin-top: 5px;
  margin-bottom: 16px;
}

textarea.Logs,
textarea.Logs:hover,
textarea.Logs:focus {
	color: #cccccc;
	background-color: #24262b;
	outline: none;
	border-color: transparent;
	font-size: small;
}

.ButtonLink {
  padding: 0;
}