.GateBody {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: var(--kemu-color-enabled-widget);
	color: inherit;
}

.GateBody textarea:focus {
	outline: none;
}

.GateBody textarea {
	width: 100%;
	height: 100%;
	resize: none;
	border: none;
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
	padding: 10px;
}

.SettingsContainer label{
	color: white;
	padding-bottom: 6px;
}


.SettingsContainer {
	min-height: 130px;
	padding: 20px 10px 10px 10px;
	background-color: var(--kemu-color-primary-darken-80);
	position: relative;
	left: -2px;
	top: -2px;

	height: calc(100% + 4px);
	width: calc(100% + 4px);

	/* Simulate gate border */
	border: 2px solid var(--kemu-color-primary-lighten-10);
}

.SwitchContainer {
	display: flex;
	flex-direction: column;
	align-items: baseline;
	padding-top: 10px;
}