.CardContainer {
  height: 100%;
  width: 100%;
}

.ServiceCard {
  margin-bottom: 16px;
  border-radius: 8px;
  overflow: hidden;
  height: 100%;
}

.Details {
  flex: 1;
}

.Header {
  display: flex;
  align-items: flex-start;
  margin-bottom: 4px;
  position: relative;
}

.IconWrapper {
  flex: 0 0 105px;
  width: 105px;
  height: 105px;
  background-color: white;
  border-radius: 10px;
  padding: 4px;
  margin-right: 20px;
}

.IconSkeleton {
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  border-radius: 8px;
}

.TitleSection {
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.TitleSkeleton {
  height: 24px;
  width: 80%;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  border-radius: 4px;
}

.AuthorSkeleton {
  height: 16px;
  width: 40%;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  border-radius: 4px;
}

.RatingSkeleton {
  height: 16px;
  width: 60%;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  border-radius: 4px;
}

.InstallBtnContainer {
  position: absolute;
  top: 0;
  right: 0;
}

.ButtonSkeleton {
  height: 24px;
  width: 80px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  border-radius: 12px;
}

.DescriptionSkeleton {
  height: 40px;
  width: 100%;
  margin: 12px 0;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  border-radius: 4px;
}

.ImageContainer {
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-radius: 8px;
}

.ImageSkeleton {
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}