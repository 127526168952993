.ByLinkSwitch{
	margin-right: 15px;
}

.InputContainer {
	margin-top: 20px;
}

.Input{
	width: calc(100% - 90px);
}

.LinkCopyBtn {
	float: right;
}