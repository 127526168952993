.PaginationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.PaginationContainer :global(ul li a) {
  color: rgba(0, 0, 0, 0.85);
}

.PaginationContainer :global(ul li a),
.PaginationContainer :global(ul li button span) {
  padding-top: 3px !important;
}

.PaginationContainer :global(ul li:focus a),
.PaginationContainer :global(ul li:focus a div span.ant-pagination-item-link-icon){
  color: var(--kemu-color-primary-lighten-20);
}

/* color on hover */
.PaginationContainer :global(ul li:hover a),
.PaginationContainer :global(ul li:hover button),
.PaginationContainer :global(ul li:hover a div span.ant-pagination-item-link-icon) {
  color: var(--kemu-color-primary-lighten-10) !important;
}


.PaginationContainer :global(ul li button) {
  border: unset !important;
}

.PaginationContainer :global(ul li) {
  border-radius: 3px;
  margin: 0 3px;
  box-shadow: 1px 1px 4px 0px rgb(0 0 0 / 20%);
  border: unset !important;
}

.PaginationContainer :global(ul li.ant-pagination-item-active) {
  border-color: unset !important;
}

.PaginationContainer :global(ul li.ant-pagination-item-active a) {
  color: white !important;
  background-color: var(--kemu-color-primary);
  height: 32px;
}