.Button {
	margin-left: .25rem;
	cursor: pointer;

	font-weight: 600;
	font-size: 12px;
	line-height: 24px;
	padding: .3rem .8rem;
	letter-spacing: .5px;

	border-radius: .25rem;
	display: inline-block;
	text-align: center;
	vertical-align: middle;
	user-select: none;
	border: 1px solid transparent;
	transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
	height: unset;
}

.Button.Loading {
	display: flex;
}

.Button:disabled{
	opacity: 0.65;
	pointer-events: none;
}

/* Secondary  */
.Button[btn-type="secondary"] {
	color: #fff;
	background-color: var(--kemu-color-secondary);
	border-color: var(--kemu-color-secondary);

	-webkit-box-shadow: 0 2px 6px var(--kemu-color-secondary);
	box-shadow: 0 2px 6px var(--kemu-color-secondary);
}

.Button[btn-type="secondary"]:not(.NoShadow):hover {
	border-color: var(--kemu-color-secondary-darken-10);
	background-color: var(--kemu-color-secondary-darken-10);
}

.Button[btn-type="secondary"]:hover:not(:active):not(.NoShadow){
	-webkit-box-shadow: 0 2px 6px var(--kemu-color-secondary-darken-10);
	box-shadow: 0 2px 6px var(--kemu-color-secondary-darken-10);
}

.Button[btn-type="secondary"]:active {
	-webkit-box-shadow: unset;
	box-shadow: unset;
	border-color: var(--kemu-color-secondary-darken-30);
	background-color: var(--kemu-color-secondary-darken-30);
}

/* Primary */
button.Button[btn-type="primary"]:not(:disabled):hover {
	color: #fff !important;
}

button.Button[btn-type="primary"] {
	color: #fff;
	-webkit-box-shadow: 0 2px 6px var(--kemu-color-primary);
	box-shadow: 0 2px 6px var(--kemu-color-primary);
	
	background-color: var(--kemu-color-primary);
	border-color: var(--kemu-color-primary);
}

.Button[btn-type="primary"]:not(.NoShadow):hover {
	border-color: transparent;
	background-color: var(--kemu-color-primary-darken-10);
}

.Button[btn-type="primary"]:not(.NoShadow):hover:not(:active){
	-webkit-box-shadow: 0 2px 6px var(--kemu-color-primary-darken-10);
	box-shadow: 0 2px 6px var(--kemu-color-primary-darken-10);
}

.Button[btn-type="primary"]:active {
	-webkit-box-shadow: unset;
	box-shadow: unset;
	border-color: var(--kemu-color-primary-darken-30);
	background-color: var(--kemu-color-primary-darken-30);
}

/* Light */
.Button[btn-type="light"] {
	background-color: var(--kemu-color-light);
	border-color: var(--kemu-color-light);
	color: #191d21;
	
	box-shadow: 0 2px 6px var(--kemu-color-light);
	-webkit-box-shadow: 0 2px 6px var(--kemu-color-light);
}

.Button[btn-type="light"]:hover {
	border-color: transparent;
	background-color: var(--kemu-color-light-darken-10);
	color: #212529;
}

.Button[btn-type="light"]:hover:not(:active){
	-webkit-box-shadow: 0 2px 6px var(--kemu-color-light-darken-10);
	box-shadow: 0 2px 6px var(--kemu-color-light-darken-10);
}

.Button[btn-type="light"]:active {
	-webkit-box-shadow: unset;
	box-shadow: unset;
	border-color: var(--kemu-color-light-darken-20);
	background-color: var(--kemu-color-light-darken-20);
}

/* Danger */
.Button[btn-type="danger"] {
	background-color: var(--kemu-color-danger);
	border-color: var(--kemu-color-danger);
	color: #fff;
	
	-webkit-box-shadow: 0 2px 6px var(--kemu-color-danger);
	box-shadow: 0 2px 6px var(--kemu-color-danger);
}

.Button[btn-type="danger"]:not(.NoShadow):hover {
	border-color: transparent;
	background-color: var(--kemu-color-danger-darken-10);
}

.Button[btn-type="danger"]:hover:not(:active):not(.NoShadow){
	-webkit-box-shadow: 0 2px 6px var(--kemu-color-danger-darken-10);
	box-shadow: 0 2px 6px var(--kemu-color-danger-darken-10);
}

.Button[btn-type="danger"]:active {
	-webkit-box-shadow: unset;
	box-shadow: unset;
	border-color: var(--kemu-color-danger-darken-30);
	background-color: var(--kemu-color-danger-darken-30);
}


/* Warning */
.Button[btn-type="warning"] {
	background-color: var(--kemu-color-warning);
	border-color: var(--kemu-color-warning);
	color: #fff;
	
	-webkit-box-shadow: 0 2px 6px var(--kemu-color-warning);
	box-shadow: 0 2px 6px var(--kemu-color-warning);
}

.Button[btn-type="warning"]:not(.NoShadow):hover {
	border-color: transparent;
	background-color: var(--kemu-color-warning-darken-10);
}

.Button[btn-type="warning"]:hover:not(:active):not(.NoShadow){
	-webkit-box-shadow: 0 2px 6px var(--kemu-color-warning-darken-10);
	box-shadow: 0 2px 6px var(--kemu-color-warning-darken-10);
}

.Button[btn-type="warning"]:active {
	-webkit-box-shadow: unset;
	box-shadow: unset;
	border-color: var(--kemu-color-warning-darken-30);
	background-color: var(--kemu-color-warning-darken-30);
}

.Button.NoShadow {
	box-shadow: none;
}