.CardBottomText {
	color: #98a6ad;
	text-align: center;
}

.SubmitButton{
	width: 50%;
}

.SubmitContainer{
	justify-content: center;
}

.AgreeContainer{
	display: flex;
	align-items: center;
	flex-wrap: inherit !important;
}

.AgreeContainer label{
	margin-bottom: 0px !important;
}

.AgreeLabel{
	padding-left: 10px;
}

.GenderContainer {
	/* display: flex;
	flex-direction: column; */
}

.GenderSelect{
	margin-bottom: 10px;
}

.GenderSelect :global(.ant-select-selection-placeholder){
	color: #7c7c7c;
}

.CustomInput{
	/* width: 80%; */
}