
@media (max-width: 575.98px) {
	.CanvasBreadcrumb {
		-ms-flex-preferred-size: 100%;
		flex-basis: 100%;
		margin-top: 10px;
	}
}

.CanvasBreadcrumb {
	/* margin-left: auto; */
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 15px;
	border-radius: 30px;

	position: absolute;
	right: 5px;
	top: 5px;
	z-index: 1;
	height: 43px;
}

.CanvasBreadcrumb .BreadcrumbItem {
	font-size: 13px;
}

.CanvasBreadcrumb .BreadcrumbItem {
	line-height: 1;
}

.CanvasBreadcrumb .BreadcrumbItem i {
	margin-right: 5px;
}

.BreadcrumbItem:global(:not(.active) a) {
	pointer-events: none;
	color: var(--kc-canvas-breadcrumb-inactive-color);
}

.BreadcrumbItem+.BreadcrumbItem::before {
	display: inline-block;
	padding-right: .5rem;
	color: var(--kc-canvas-breadcrumb-inactive-color);
	content: "/";
}

.BreadcrumbItem+.BreadcrumbItem {
	padding-left: .5rem;
}

.BreadcrumbItem:global(.active) a {
	color: var(--kc-canvas-breadcrumb-color);
}

:global(.custom-widget) .BreadcrumbItem:global(.active) a {
	color: var(--kc-custom-widget-mode-canvas-breadcrumb-color);
}

:global(.custom-widget) .BreadcrumbItem:global(:not(.active)) a {
	color: var(--kc-custom-widget-mode-canvas-breadcrumb-inactive-color);
}

/* =========== Dark theme ======== */
.dark-theme .section .section-header .section-header-breadcrumb {
	background: #353c48;
}
