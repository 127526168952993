.ModalContainer :global(.kemu-modal-body){
	padding: 0px;
}

.ModalContainer :global(.kemu-modal-content){
	padding: 0px;
	background-color: transparent;
	box-shadow: none;
}

.Footer{
	text-align: right;
}

.CardShadow{
	box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
}
