.GateBody {
	display: flex;
	width: 108px;
	/* padding: 0px 5px; */
	flex-direction: column;
}

.SettingsContainer {
	width: 112px;
	/* height: auto; */
	height: calc(100% + 4px);
	min-height: 100px;
	padding: 20px 10px 10px 10px;
	position: relative;
	left: -2px;
	top: -2px;
}

.SettingsContainer label{
	color: white;
}



.InputContainer{
	margin-top: 15px;
	display: flex;
	flex-direction: column;
}

.Button {
	padding: 2px 5px;
	margin-bottom: 10px;
}