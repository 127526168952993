.UserInfo {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 10px;
}

.UserInfo small {
	color: #b8b8b8;
}

.LogoutContainer {
	display: flex;
	justify-content: center;
	margin-top: 20px;
}