.container {
	position: relative;
	/* padding-left: 20px;
	padding-right: 20px; */
}

.inputGroup {
	position: relative;
	display: -ms-flexbox;
	display: -webkit-box;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-align: stretch;
	-webkit-box-align: stretch;
	align-items: stretch;
	width: 100%;
}

.formControl {
	border-radius: 30px 0 0 30px;
	height: 31px;
	font-size: 13px;
	padding: 10px 15px;
	position: relative;
	-ms-flex: 1 1 auto;
	-webkit-box-flex: 1;
	flex: 1 1 auto;
	width: 1%;
	margin-bottom: 0;
	background-color: #fdfdff;
	border-color: #e4e6fc;
	font-weight: 400;
	line-height: 1.5;
	display: block;
	color: #495057;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
	overflow: visible;
	margin: 0;
	font-family: inherit;
}

.formControl:focus{
	box-shadow: none;
	outline: 0;
	background-color: #fefeff;
  border-color: var(--kemu-color-primary-lighten-20);
}

.btn {
	border-radius: 0 30px 30px 0;
	margin-top: -1px;
	font-size: 12px;
	padding-left: 13px !important;
	padding-right: 13px !important;
	padding: 2px 15px;
	box-shadow: 0 2px 6px var(--kemu-color-primary-lighten-20);
	background-color: var(--kemu-color-primary);
	border-color: var(--kemu-color-primary);
	font-weight: 600;
	letter-spacing: .5px;
	color: #fff;
	display: inline-block;
	user-select: none;
	line-height: 24px;
	border-style: solid;
	cursor: pointer;
}

.btn:active {
	background-color: var(--kemu-color-primary-darken-20);
	box-shadow: none;
  outline: 0;
}

.btn:hover{
	border-color: transparent;
	background-color: var(--kemu-color-primary-lighten-20);
}

.btn:focus {
	background-color: var(--kemu-color-primary-lighten-20);
	box-shadow: none;
	outline: 0;
}