.PreviewWrapper {
	background-color: #fff;
  border-radius: 4px;
	z-index: 302;
	/* border: 2px solid var(--kemu-color-enabled-widget); */
	border-width: 2px;
	border-style: solid;
}

.Header {
	height: 20px;
	line-height: 20px;
	color: #fff;
	text-align: center;
	text-transform: uppercase;
	font-size: 7pt;
	font-weight: bolder;
	/* background-color: var(--kemu-color-enabled-widget); */
}

.Title {
	user-select: none;
}

.Body {
	min-height: 30px;
  width: 100px;
	display: flex;
	align-items: center;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: #fff;
}

.HorizontalBody {
	width: 150px;
}

.HorizontalIcon {
	width: 35px;
  height: 41px;
	color: #fff;
	text-align: center;
	line-height: 41px;
	float: left;
	/* background-color: var(--kemu-color-enabled-widget); */
}

.HorizontalRightIcon {
	float: right;
	margin-left: auto;
}

.FullBody svg {
	width: 42px;
	height: 42px;
}

.FullBodyHeader {
	height: 16px;
	width: 100%;
	line-height: 20px;
	color: #fff;
	text-align: center;
	text-transform: uppercase;
	font-size: 7pt;
	font-weight: bolder;
	/* background-color: var(--kemu-color-enabled-widget); */
}

.FullBody {
	display: flex;
	width: 62px;
	height: 64px;
	overflow: hidden;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	/* background-color: var(--kemu-color-enabled-widget); */
	color: white;
	padding-bottom: 1px;
}

/* Transformer types */
.PreviewWrapper:global(.ctgry-transformer) { border-color: var(--kemu-color-widget-type-transformer); }
.PreviewWrapper:global(.ctgry-transformer) .FullBody,
.PreviewWrapper:global(.ctgry-transformer) .FullBodyHeader,
.PreviewWrapper:global(.ctgry-transformer) .HorizontalIcon,
.PreviewWrapper:global(.ctgry-transformer) .Header {
	background-color: var(--kemu-color-widget-type-transformer);
}

/* Conditional types */
.PreviewWrapper:global(.ctgry-condition) { border-color: var(--kemu-color-widget-type-condition); }
.PreviewWrapper:global(.ctgry-condition) .FullBody,
.PreviewWrapper:global(.ctgry-condition) .FullBodyHeader,
.PreviewWrapper:global(.ctgry-condition) .HorizontalIcon,
.PreviewWrapper:global(.ctgry-condition) .Header {
	background-color: var(--kemu-color-widget-type-condition);
}

/* Logic types */
.PreviewWrapper:global(.ctgry-logic) { border-color: var(--kemu-color-widget-type-logic); }
.PreviewWrapper:global(.ctgry-logic) .FullBody,
.PreviewWrapper:global(.ctgry-logic) .FullBodyHeader,
.PreviewWrapper:global(.ctgry-logic) .HorizontalIcon,
.PreviewWrapper:global(.ctgry-logic) .Header {
	background-color: var(--kemu-color-widget-type-logic);
}

/* Producer types */
.PreviewWrapper:global(.ctgry-producer) { border-color: var(--kemu-color-widget-type-producer); }
.PreviewWrapper:global(.ctgry-producer) .FullBody,
.PreviewWrapper:global(.ctgry-producer) .FullBodyHeader,
.PreviewWrapper:global(.ctgry-producer) .HorizontalIcon,
.PreviewWrapper:global(.ctgry-producer) .Header {
	background-color: var(--kemu-color-widget-type-producer);
}

/* Consumer types */
.PreviewWrapper:global(.ctgry-consumer) { border-color: var(--kemu-color-widget-type-consumer); }
.PreviewWrapper:global(.ctgry-consumer) .FullBody,
.PreviewWrapper:global(.ctgry-consumer) .FullBodyHeader,
.PreviewWrapper:global(.ctgry-consumer) .HorizontalIcon,
.PreviewWrapper:global(.ctgry-consumer) .Header {
	background-color: var(--kemu-color-widget-type-consumer);
}

/* Storage types */
.PreviewWrapper:global(.ctgry-storage) { border-color: var(--kemu-color-widget-type-storage); }
.PreviewWrapper:global(.ctgry-storage) .FullBody,
.PreviewWrapper:global(.ctgry-storage) .FullBodyHeader,
.PreviewWrapper:global(.ctgry-storage) .HorizontalIcon,
.PreviewWrapper:global(.ctgry-storage) .Header {
	background-color: var(--kemu-color-widget-type-storage);
}

/* Custom types */
.PreviewWrapper:global(.ctgry-custom) { border-color: var(--kemu-color-widget-type-custom); }
.PreviewWrapper:global(.ctgry-custom) .FullBody,
.PreviewWrapper:global(.ctgry-custom) .FullBodyHeader,
.PreviewWrapper:global(.ctgry-custom) .HorizontalIcon,
.PreviewWrapper:global(.ctgry-custom) .Header {
	background-color: var(--kemu-color-widget-type-custom);
}