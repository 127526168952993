.GateBody {
	display: flex;
	width: 100px;
	padding: 0px 5px;
}

.SettingsContainer {
	width: 104px;
	padding: 15px 10px 10px 10px;
	left: -2px;
	top: -2px;
	position: relative;
}

.Input input{
	border-bottom-left-radius: 0px !important;
	border-bottom-right-radius: 0px !important;
	background-color: #ffffffc7;
}

.Slider{
	width: 100%;
}

.Slider :global(.kemu-slider-track){
	background-color: var(--kemu-color-widget-type-producer-settings-border);
}

.Slider :global(.kemu-slider:hover .kemu-slider-track){
	background-color: var(--kemu-color-widget-type-producer-settings-container);
}

.Slider :global(.kemu-slider-handle:hover)::after,
.Slider :global(.kemu-slider-handle:focus)::after {
	box-shadow: 0 0 0 4px var(--kemu-color-widget-type-producer-settings-container);
}


.Slider :global(.kemu-slider-handle)::after{
	box-shadow: 0 0 0 2px var(--kemu-color-widget-type-producer-settings-container);
}

.SettingsContainer label{
	color: white;
}