label.Label {
  margin-top: 0.4rem;
}

.Label.NoMb {
  margin-bottom: 0px;
}

.Label.DarkLabel {
  color: var(--kc-canvas-gate-port-label);
}