.Wrapper {
  width: 100%;
  min-height: 480px;
}

.RowsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  gap: 5px;
}

.SettingRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.RowGroup {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.FieldTypeSelect {
  width: 150px !important;
  /* border-top-right-radius: 0px;
  border-bottom-right-radius: 0px; */
}

.Checkbox {
  width: 110px;
  text-align: center;
  justify-content: center;
  /* margin-left: 0px !important; */
}

.SettingGearIcon button {
  height: 44px;
  border-left: none;
  margin-left: unset;
  /* border: 1px solid #ced4db;
  border-color: #ced4db !important;
  border-left: none; */
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;

  border: none;
  box-shadow: unset;border: none;
  box-shadow: unset;
}

.SettingGearIcon button:hover {
  background-color: #ced4db21;
}

.SettingGearIcon button:hover,
.SettingGearIcon button:active,
.SettingGearIcon button:focus {
  border-color: #ced4db;
}

.InputType {
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.InputType input:not(.BadVariableInput) {
  border: 0px;
}

.InputType input {
  width: 110px;
  padding: 10px 10px;
  padding-left: 18px;
  text-align: center;
}

.InputType input:hover {
  background-color: #fafafa;
}

.InputType input:focus {
  background-color: #f8f8f8;
}


.Headers {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 10px;
  font-weight: 700;
  padding-bottom: 6px;
  border-bottom: 1px solid #ced4db;
}

.VerticalGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Headers .VerticalGroup {
  justify-content: space-between;
  height: auto;
}

.Headers div {
  text-align: center;
}

.Headers div:nth-child(1){
  width: 180px;
}

.Headers div:nth-child(2){
  width: 120px;
  margin-left: 10px;
}

.Headers div:nth-child(3){
  width: 125px;
  margin-left: -4px;
}

.Headers div:nth-child(4){
  width: 106px;
  margin-left: 3px;
}

.Headers div:nth-child(5){
  width: 110px;
}

.Headers div:nth-child(6){
  width: 100px;
}

.AddSettingBtn {
	border-radius: 0px;
	margin: 0px;
	border-left: 0px;
	border-color: #ced4da;
	padding: .2rem .4rem;
  margin-top: 20px;
  border-radius: 4px;
  width: 100%;
}

.AddFieldContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}


.RemoveFieldBtn {
	border-radius: 0px;
	margin: 0px;
	border-left: 0px;
	border-color: transparent !important;
	padding: .2rem .4rem;
}

.RemoveFieldBtn svg {
  color: var(--kemu-color-danger-lighten-10);
}

