.PortsContainer {
	display: flex;
	flex-direction: column;
	/* margin-top: 25px; */
	width: 100%;
}

.PortRow {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.PortName {
	height: 30px;
  border-radius: 0px;
	border-right: none;
	width: 110px;	
	padding: 5px 6px;
}

.PortRow:not(:nth-child(2)) .PortName,
.PortRow:not(:nth-child(2)) .PortType{
	border-top: none;
}


.PortType {
	border-radius: 0px;
	min-height: unset;
	height: 30px;
}

.PortType :global(.ant-select-selector){
	padding: 0px 2px !important;
}

.Headers label:first-child {
	width: 25%;
}

.PortRow:not(:nth-child(2)) .PortActionBtn {
	border-top: 0px;
}

.PortActionBtn {
	border-radius: 0px;
	margin: 0px;
	border-left: 0px;
	/* border-color: #ced4da; */
	padding: .2rem .4rem;
	border: unset;
}

.RemovePortBtn svg {
  color: var(--kemu-color-danger-lighten-10);
}