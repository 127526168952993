.GateBody {
	display: flex;
	width: 110px;
}

.SettingsContainer {
	width: 114px;
	min-height: 100px;
	padding: 20px 10px 10px 10px;
	position: relative;
	left: -2px;
	top: -2px;
}

.SettingsContainer label{
	color: white;
}

.SettingsContainer input {
	border-radius: 3px;
	border: none;
	outline: none;
}


.TagsContainer {
	display: flex;
	flex-direction: column;
}

.TagsContainer input {
	font-size: 14px;
  font-weight: 400;
	padding: 4px;
	width: 100% !important;
}

.TagsElement :global(.ant-select-selector) {
	/* background-color: #716f91 !important; */
	background-color: var(--kemu-color-widget-type-transformer-settings-input) !important;
	border: none !important;
	border-radius: 3px !important;
}

.TagsDropdown :global(.ant-select-item-option-selected){
	background-color: white;
}

/* Tick */
/* .TagsDropdown :global(.ant-select-item-option-selected .ant-select-item-option-state){

} */