.Card{
	border-top-color: var(--kemu-color-primary);
	border-top-style: solid;
	border-top-width: 2px;
	box-shadow: 0 4px 12px 0px rgb(0 0 0 / 28%);
  background-color: #fff;
	background-color: #fff;
	border-radius: 10px;
	position: relative;
	margin-bottom: 30px;

	display: flex;
	-ms-flex-direction: column;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	min-width: 0;
	background-clip: border-box;
}

.Card:global(.success){
	border-top-color: var(--kemu-color-success);
}

.Card:global(.warning){
	border-top-color: var(--kemu-color-warning);
}

.Card:global(.danger){
	border-top-color: var(--kemu-color-danger);
}

.CardHeader{
	line-height: 30px;
	align-self: center;
	width: 100%;
	padding: 10px 25px;
	display: flex;
	align-items: center;
	box-sizing: border-box;
	margin-bottom: 0px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}

.CardHeader h4 {
	font-size: 16px;
	line-height: 28px;
	padding-right: 10px;
	margin-bottom: 0;
	font-weight: 700;
	font-family: 'Nunito','Segoe UI',arial;
  color: #6c757d;
}


.CardBody{
	padding-top: 20px;
	padding-bottom: 20px;
	flex: 1 1 auto;
}


.CardBody,
.CardFooter{
	background-color: transparent;
  padding: 20px 25px;
}