/* .GateWrapper:global(:not(.original-event)) {
	border: none !important;
	background-color: transparent !important;
} */

.GateWrapper:global(.widget-disabled),
.GateWrapper:global(:not(.original-event)){
	border: none !important;
	background-color: transparent !important;
}

.GateWrapper:global(.original-event){
	border-radius: 7px;
}

.GateWrapper:global(.widget-disabled) .WidgetTypeContainer{
	background-color: var(--kemu-color-disabled-widget);
}

.GateWrapper:global(:not(.widget-disabled).original-event) .WidgetTypeContainer{
	background-color: var(--kemu-color-original-event-widget);
}

.GateWrapper :global(.cg-body){
	background-color: transparent !important;
}

.GateBody {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	min-height: 54px;
}


/* .GateBody :global(.cg-body) {
	background-color: transparent;
} */

.WidgetTypeContainer {
	min-height: 57px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--kemu-color-primary-lighten-10);
	border-radius: 5px;
	align-items: center;
}


/* Only hidden fields */
.WidgetTypeContainer.HasHiddenFields:not(.HasVisibleFields) {
	align-items: start;
}

.WidgetTypeContainer.HasMultiPort {
	align-items: start;
}

.WidgetTypeContainer:not(.HasVisibleFields) {
	width: 80px;
}


.WidgetTypeContainer:hover .VersionContainer {
	opacity: 1;
}

.VersionContainer {
	/* color: white; */
	color: black;
	right: 2px;
	bottom: 2px;
	border-radius: 3px;
	position: absolute;
	padding: 0px 2px;
	/* border: 1px solid #fffefe; */
	border: 1px solid #eee;
	background-color: #fff;
	font-size: 10px;
	opacity: 0;
	transition: opacity 0.1s ease-out;
}

.WidgetTypeContainer .Icon {
	font-size: 50px;
	color: white;
}

.FolderTypeContainer .Icon {
	font-size: 50px;
	color: var(--kemu-color-primary-lighten-10);
}

.Label {
	max-width: 150px;
	text-align: center;
	margin-top: 5px;
	position: absolute;
	bottom: -5px;
	transform: translateY(100%);
	width: max-content;

	font-family: 'Nunito';
	font-style: normal;
	font-weight: 600;
	/* width: 200%; */
	color: var(--kc-canvas-gate-port-label);
}

.Label.NotEditable {
	pointer-events: none;
}


.GateBody:hover .Label {
	color: var(--kemu-color-primary)
}

:global(.custom-widget) .Label {
	color: var(--kc-custom-widget-mode-canvas-gate-port-label);
}

.Label input{
	text-align: center;
	border: 1px solid hsl(245deg 100% 62% / 40%);
	outline: none;
	background-color: white;
	color: #34395e;
	border-radius: 25px;
	width: fit-content;
}

.SettingsContainer {
	min-width: 160px;
	min-height: 110px;
	padding: 20px 10px 10px 10px;
	background-color: var(--kemu-color-primary-darken-80);
	position: relative;
	left: -2px;
	top: -2px;

	/* height: calc(100% + 4px); */
	/* Simulate gate border */
	/* border: 2px solid var(--kemu-color-primary-lighten-10); */
	border: 2px solid;
	width: fit-content;
}

.SettingsContainer .Header {
	background-color: transparent;
	padding-top: 4px;
	width: 100%;
}

.SettingsContainer .Header.LongName {
	padding-right: 24px;
}

.SettingsHeader {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 26px;
}

.SettingsContent {
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-top: 15px;
}

.WidgetTypeContainer.CustomSettingsVisible {
	display: none;
}

.VariableInUseWarning {
	position: absolute;
	bottom: -10px;
	right: -10px;
	width: 30px;
	height: 30px;
	background-color: var(--kemu-color-warning);
	/* color: var(--kemu-color-warning); */
	color: white;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 1.6em;
}

.WidgetWithBugs {
	position: absolute;
	bottom: -10px;
	left: 50%;
	translate: -50%;
}

.WidgetWithBugs span {
	width: 20px;
	height: 20px;
	padding: 2px;
	background-color: var(--kemu-color-danger);
	color: white;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 25px;
}