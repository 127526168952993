.ViaLinkContainer {

}

.InputContainer {
	margin-top: 20px;
}

.Input{
	width: calc(100% - 90px);
}

.LinkCopyBtn {
	float: right;
	height: 42px;
}

.UserAddressInput {
	width: calc(100% - 55px);
}

.AddUserBtn {
	float: right;
}

.ByLinkSwitch {
	margin-right: 15px;
}


.PeopleContainer {
	margin-top: 40px;
}

.PermissionDropdown {
	margin-left: auto;
	margin-right: 5px;
	border: none;
}

.PersonItem {
	display: flex;
	align-items: center;
	padding: 10px;
}

.PersonPicture {
	border-radius: 50%;
	background-color: red;
	color: white;
	width: 45px;
	height: 45px;
	text-align: center;
	line-height: 45px;
	font-size: 24px;
	margin-right: 10px;
}

.PersonPicture:global(.c0){
	background-color: var(kemu-color-block-light-0)
}

.PersonPicture:global(.c1){
	background-color: var(kemu-color-block-light-1)
}

.PersonPicture:global(.c2){
	background-color: var(kemu-color-block-light-2)
}

.PersonPicture:global(.c3){
	background-color: var(kemu-color-block-light-3)
}

.PersonPicture:global(.c4){
	background-color: var(kemu-color-block-light-4)
}

.PersonPicture:global(.c5){
	background-color: var(kemu-color-block-light-5)
}

.PersonPicture:global(.c6){
	background-color: var(kemu-color-block-light-6)
}

.PersonPicture:global(.c7){
	background-color: var(kemu-color-block-light-7)
}

.PersonPicture:global(.c8){
	background-color: var(kemu-color-block-light-8)
}

.PersonPicture:global(.c9){
	background-color: var(kemu-color-block-light-9)
}

.PersonPicture:global(.c10){
	background-color: var(kemu-color-block-light-10)
}

.PersonInfo {
	display: flex;
	flex-direction: column;
	padding: 10px 0px;
}

.PersonName {
	font-weight: 700;
}

.PersonEmail {
	opacity: 0.7;
}

.NotSelected {
	width: 20px;
	display: inline-block;
}

.PersonList {
	margin-top: 35px;
	max-height: 130px;
	overflow: hidden;
	overflow-y: auto;
	border-top: 1px solid #ededed;
}

.LoadingIcon {
	/* font-size: 32px; */
}