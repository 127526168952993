.GateBody {
	width: 112px;
	/* padding: 0px 5px; */
}

.InnerBody{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
}

.Title {
	font-weight: 900;
}

.SettingsContainer {
	width: 116px;
	height: calc(100% + 4px);
	min-height: 100px;
	padding: 20px 10px 10px 10px;
	position: relative;
	left: -2px;
	top: -2px;
}

.SettingsContainer label{
	color: white;
}


.InputContainer{
	margin-top: 15px;
	display: flex;
	flex-direction: column;
	align-items: center;
}
