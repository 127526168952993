.CloseBtn{
	color: #eee;
	width: 20px;
	height: 20px;
	position: absolute;
	cursor: pointer;
	text-align: center;
	line-height: 20px;
	
	background-color: transparent;
	font-size: 20px;
	right: 3px;
	top: 3px;
	/* mix-blend-mode: difference; */
	opacity: 0.7
}

.CloseBtn:hover:not(:active){
	color: #fff;
	opacity: 1;
}