.Sider :global(.ant-layout-sider-zero-width-trigger-left){
	z-index: 2;	
}

.Sider :global(main){
	min-width: 300px;
}

.Sider {
	height: 100%;
	min-height: 300px;
	left: 0;
	/* position: fixed; */
	/* overflow: auto; */
}

/* Added for specificity reasons only */
#Menu {}

#Menu .MenuItem {
	/* background-color: red; */
	margin-top: 0px;
	margin-bottom: 0px;
	height: var(--marketplace-subcategory-height);
	line-height: var(--marketplace-subcategory-height);
	display: flex;
  align-items: center;

	margin: 0;
	width: 100%;
	border-radius: 0;
}

#Menu .MenuItemSeparator {
	position: relative;
	top: 0px;
	left: 0px;
	display: block;
	width: 100%;
	height: 1px;
	background: #f0f0f0;
	content: "";
	margin-bottom: 4px;
}

.MenuItemSelected {
	/* background-color: var(--kemu-color-gray-darken-30); */
	background-color: var(--kemu-color-primary);
	color: white;
}

.MenuItemSelected:not(:hover) {
	color: white !important;
}

.MenuItemSelected:hover {
	color: #f6f6f6 !important;
	background-color: var(--kemu-color-primary) !important;
}

.MenuItem::after {
	border-right: none;
}

.MenuItem:not(.MenuItemSelected):hover {
	color: #f6f6f6;
	/* background-color: #b8b3ad; */
	/* background-color: var(--kemu-color-primary-lighten-40); */
}

.MenuItem :global(.ant-menu-item-icon){
	font-size: 24px;
}