.Content {
  display: flex;
  flex-direction: column;
}

.Content input {
	font-family: 'Nunito';
	font-style: normal;
	font-weight: 400;
}


.ColorContainer {
	width: 100px;
	/* display: flex;
	flex-direction: column; */
}

.ColorAndIconRow {
	display: flex;
	margin-top: 15px;
	justify-content: space-between;
	width: 160px;
	align-items: center;
}

.Swatch {
	margin-top: 6px;
	width: 50px;
	display: block;
}

.SwatchPopup{
	margin-top: 12px;
	padding-bottom: 30px;
}

.LogsContainer {
  margin-bottom: 10px;
}

input.CompactInput {
	padding: 4px 11px;
	height: 32px;
}

input.NameInput {
	padding-left: 42px;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
}

.TestPrefix {
	position: absolute;
	bottom: 6px;
	padding-left: 16px;
	color: #495057;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.5;
}

.InputWithPrefix {
	width: 100%;
	position: relative;
}

.InputBtn { 
	margin-top: 29px;
}

.SwitchGroup {
	gap: 9px;
}

.ProjectPath {
	margin-top: 6px;
	font-size: small;
}

.HubOfflineMsg {
	margin-top: 10px;
	color: red;
}

textarea.Logs,
textarea.Logs:hover,
textarea.Logs:focus {
	color: #cccccc;
	background-color: #24262b;
	outline: none;
	border-color: transparent;
	font-size: small;
}
