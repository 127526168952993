.SettingsVariableName {
  display: flex;
  flex-direction: column;
}

.SettingsVariableNameInput {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* .SettingsVariableName.HasError .SettingsVariableNameInput span {
  color: var(--kemu-color-danger);
} */

/* .SettingsVariableNameInput span {
  margin-right: -18px;
  z-index: 1;
} */

.BadVariableInput,
.BadVariableInput:focus {
  color: var(--kemu-color-danger);
  border-color: 1px solid var(--kemu-color-danger);
}



.InputType {
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.InputType input:not(.BadVariableInput) {
  border: 0px;
}

.InputType input {
  width: 130px;
  padding: 10px 10px;
  /* padding-left: 18px; */
  text-align: center;
}

.InputType input:hover {
  background-color: #fafafa;
}

.InputType input:focus {
  background-color: #f8f8f8;
}