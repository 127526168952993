.SettingsDotsContainer{
	position: absolute;
	right: 5px;
	bottom: 10px;
}

.SettingsButton{
	border: none;
	background-color: transparent;
	border-radius: 10px;
}

.SettingsButton:active,
.SettingsButton:focus{
	border: none;
	outline: none;
}

.SettingsButton:hover:not(:active){
	background-color: whitesmoke;
}