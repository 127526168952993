.Wrapper:global(.success .ant-modal-confirm-body span[role='img']){
	color: #52c41a;
}

.Wrapper:global(.warning .ant-modal-confirm-body span[role='img']){
	color: var(--kemu-color-warning);
}

.Wrapper:global(.info .ant-modal-confirm-body span[role='img']){
	color: #1890ff;
}

.Wrapper:global(.danger .ant-modal-confirm-body span[role='img']){
	color: var(--kemu-color-danger);
}