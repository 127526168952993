.GateBody {
	/* display: flex;
	width: 100px;
	height: 20px;
	flex-direction: column; */
	display: flex;
  width: 70px;
  height: 42px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: var(--kemu-color-widget-type-transformer);
	color: white;
}

.DisabledWidget {
	background-color: var(--kemu-color-disabled-widget);
}

.GateBody svg {
	width: 50px;
	height: 50px;
}

.SettingsContainer {
	width: 132px;
	min-height: 146px;
	padding: 20px 10px 10px 10px;
	position: relative;
	left: -2px;
	top: -2px;

	height: calc(100% + 4px);
}

.SettingsContainer label{
	color: white;
}


.InputContainer{
	margin-top: 10px;
	display: flex;
	flex-direction: column;
}

.InputContainer :global(input) {
	border-bottom-left-radius: 0px !important;
	border-bottom-right-radius: 0px !important;
	border-top-left-radius: 0px !important;
	border-top-right-radius: 0px !important;
}



.SwitchContainer {
	display: flex;
	flex-direction: column;
	align-items: baseline;
	padding-top: 10px;
}
