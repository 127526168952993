.GateBody {
	display: flex;
	width: 100px;
}

.InputContainer::after{
	content: 'ms';
	position: absolute;
	right: 4px;
	bottom: 3px;
}

.InputContainer:focus-within::after {
	content: ''
}

/* Chrome, Safari, Edge, Opera */
.Input::-webkit-outer-spin-button,
.Input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
