.GateBody {
	display: flex;
	width: 100px;
}

.MinInput {
	width: 50%
}

.MaxInput {
	width: 50%
}