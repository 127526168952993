
.IconsGrid {
	height: 210px;
	width: 400px;
	overflow: auto;
	color: #4f5473;
}

.PreviewContainer {
	background-color: var(--kemu-color-primary);
	border-radius: 25px;
	display: flex;
	width: 32px;
	height: 32px;
	justify-content: center;
	align-content: center;
	align-items: center;
	color: white;
	cursor: pointer;
	box-shadow: 0 0 5px 1px #8b8b8b6e;
}

.SelectedIcon {
	font-size: 20px;
	color: inherit;
}

.DefaultIcon {
	font-size: 30px;
	color: inherit;
}

.GridIcon {
	display: inline-block;
	margin: 2px;
	font-size: 26px;
	cursor: pointer;
}

.GridIcon:hover {
	color: var(--kemu-color-primary-lighten-30);
}


.PopupOverlay {
	
}

.SearchBox {
	margin-bottom: 10px;
	display: flex;
	flex-wrap: nowrap;
	align-items: flex-end;
}


.SearchBox span {
	font-size: 11px;
	color: #9e9e9e;
	padding-left: 10px;
}

.SearchInput {
	width: 150px;
	height: 25px;
	border-radius: 25px;
}