.GateBody {
	display: flex;
	width: 130px !important;
	height: 80px !important;
	flex-direction: column;
}

.Row {
	display: flex;
	flex-direction: row;
}

.Row :global(.gate-input input){
	border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
	color: #666;
	background-color: #ffffffc7;
}