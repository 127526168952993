.GateBody {
  display: flex;
  width: 52px;
  height: 55px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--kemu-color-widget-type-logic);
  color: white;
}

.WidgetDisabled {
  background-color: var(--kemu-color-disabled-widget);
}


.GateBody svg {
  width: 35px;
  height: 50px;
}