.Panel {
  min-width: 166px;
  min-height: 50px;
  background-color: #181818;
  color: white;
  padding: 5px;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  position: absolute;
  /* On top of the port label */
  z-index: 303;
  overflow: hidden;
  /* animation: hide 0.3s ease-in-out; */
  /* animation-fill-mode: forwards; */
}

.Panel.InputType {
  height: 34px;
  min-height: unset;
  min-width: unset;
}

.ForcePanelInvisible {
  display: none;
}


.PanelVisible {
  animation: show 0.6s ease-in-out;
  animation-fill-mode: forwards;
}

/* .PanelVisible:hover {
  animation: unset;
  opacity: 1;
  visibility: visible;
} */

/* Animation of Panel that makes it invisible after 0.3s */
@keyframes hide {
  0% {
    opacity: 1;
  }
  /* 70% {
    opacity: ;
  } */
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

/* Animation of Panel that makes it visible after 0.3s */
@keyframes show {
  0% {
    opacity: 0;
    visibility: visible;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.Row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  padding: 0px 6px;
}

.Panel.InputType :global(.react-resizable-handle) {
  display: none;
}

.Panel.InputType .Row {
  justify-content: space-between;
}

.ExpandedDetails {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  visibility: hidden;
  transition: height 0.2s ease-in-out;
}

/* Style scroll bar of ExpandedDetails */
.ExpandedDetails::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.ExpandedDetails::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.ExpandedDetails::-webkit-scrollbar-thumb {
  background: #888;
}

.ExpandedDetails::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.ExpandedDetails.Hidden {
  display: none;
}

.DetailsVisible {
  min-height: 120px;
  visibility: visible;
}

.Label {
  font-size: 14px;
  font-weight: bold;
}

.Value.Result {
  color: rgb(193, 125, 101);
}

.Value.Type {
  color: rgb(67, 191, 160);
}

.Value {
  font-size: 14px;
  margin-left: 5px;
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.Value.Clickable {
  cursor: pointer;
}
