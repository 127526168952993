
.FooterTitle {
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 5px;
}

.FooterDescription {
	width: calc(100% - 32px);
	height: 100%;
  overflow-y: auto;
	color: var(--widget-launchpad-button-text-color, gray);
}


.FooterDescription::-webkit-scrollbar {
	display: none;
}

.FooterActionButtons {
	width: 32px;
	height: 100%;
	/* background-color: yellow; */
	top: 8px;
	position: absolute;
	display: flex;
	right: 0px;
	justify-content: flex-start;
	flex-direction: column;
}


.FooterActionButtons button,
.FooterActionButtons button:hover, 
.FooterActionButtons button:focus {
	color: var(----widget-launchpad-section-header-color);
}

.FooterActionButtons button:hover {
	background-color: #fcddcf;
}