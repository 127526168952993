.Button {
	/* background-color: transparent; */
	width: unset;
	display: flex;
  align-items: center;
}

.Button[btn-type='transparent'] {
	border-color: var(--kemu-color-light);
	color: var(--kemu-color-dark);
}

.NoShadow {
	box-shadow: none !important;
}