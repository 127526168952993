
.CustomWidgetButton:hover {
	z-index: 100;
}

.CustomWidgetButton:global(.text-expanded .gate-name){
	/* background-color: var(--widget-launchpad-bg-color);
	transition: background-color 0.2s ease-in-out; */
	padding: 2px 5px 4px;
	margin-top: 9px;
	width: 110px;
	/* box-shadow: 0px 1px 20px 1px #00000085; */
}


.CustomWidgetButton:global(.disabled) {
	opacity: 0.35;
}

.CustomWidgetButton {
	border-radius: 10px;
	position: relative;
	text-align: center;
	width: 75px;
	cursor: pointer;
	transition: transform 0.35s;
	transition-delay: 100ms;
	will-change: transform;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding: 5px 0px;
	margin: 6px;
	height: 100px;
	box-sizing: border-box;
}


.CustomWidgetButton :global(.logic-gate-icon::after) {
	content: '';
	border-radius: 7px;
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	border-width: 3px;
	border-style: solid;
	border-color: white;
	opacity: 0;
	transition: opacity 0.1s ease-in-out;
	will-change: opacity;
}

.CustomWidgetButton:global(.selected .logic-gate-icon::after){
	box-shadow: 0px 0px 12px 8px hsl(0deg 0% 0% / 20%);
	opacity: 1;
	margin-left: -3px;
  margin-top: -3px;
}

.CustomWidgetButton :global(.logic-gate-icon) {
	transition: transform 0.35s ease-in-out 100ms;
	will-change: transform;
}

.CustomWidgetButton:global(:not(.disabled):hover .logic-gate-icon) {
	transform: scale(1.2);
	/* transition-delay: 100ms; */
	-webkit-font-smoothing: subpixel-antialiased;
}

.CustomWidgetButton :global(.logic-gate-icon) {
	font-size: 25px;
	line-height: 25px;
	color: white;
	background-color: var(--kemu-color-secondary);
	padding: 10px;
	border-radius: 7px;
	width: 60px;
	margin: 0 auto;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.CustomWidgetButton :global(.gate-name) {
	white-space: normal;
	margin-top: 7px;
	margin-bottom: 3px;
	font-weight: 600;
	color: var(--widget-launchpad-button-text-color, gray);
	position: relative;
	line-height: normal;
}

.CustomWidgetButton :global(.logic-gate-icon) span.anticon{
	font-size: 30px;
}


.DeleteBtn {
	position: absolute;
	top: -5px;
	right: -5px;
	font-size: 15px;
	color: var(--kemu-color-secondary);
	cursor: pointer;

	visibility: hidden;
}

.WarningBtn {
	position: absolute;
	top: -5px;
	left: 2px;
	font-size: 15px;
	color: var(--kemu-color-warning);
	cursor: pointer;
}


.DeleteBtn:hover{
	color: var(--kemu-color-danger-darken-80);
}

.CustomWidgetButton:hover .DeleteBtn {
	animation: showButtonSlow 1s forwards;
}

@keyframes showButtonSlow {
	0% {
		visibility: hidden;
		height: 0px;
	}

	90% {
		visibility: hidden;
		height: 0px;
	}

	100% {
		visibility: visible;
		height: 15px;
	}
}