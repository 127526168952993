.CheckboxField {
  height: auto;
  border: none;
  outline: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  color: #666;
  font-size: 14px;
  font-weight: 400;
  padding: 4px;
  text-align: center;
}

.Group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  gap: 5px;
}

.SingleElement {
  padding: 5px 0px;
}

.Pb5 {
  padding-bottom: 5px;
}