.ErrorMessage{
	width: 100%;
	margin-top: .25rem;
	font-size: 80%;
	color: #dc3545;
}


textarea.InvalidInput {
	border-color: #dc3545;
	padding-right: calc(1.5em + .75rem);
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
	background-repeat: no-repeat;
	background-position: center right calc(.375em + .1875rem);
	background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}

.InvalidInput:focus {
	border-color: #dc3545;
}

textarea.NoResize {
	resize: none;
}