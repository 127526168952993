.Accordion :global(.ant-collapse-content-box){
	padding: 0px;
}

.Accordion :global(.ant-collapse-header){
	padding-left: 25px !important;
}

.Accordion :global(.ant-collapse-header .ant-collapse-arrow){
	left: 0px !important;
}

.ColorContainer {
	width: 100px;
}

.ColorAndIconRow {
	display: flex;
	margin-top: 15px;
	justify-content: space-between;
	width: 160px;
	align-items: center;
}

.Swatch {
	width: 50px;
	display: block;
}

.SwatchPopup{
	margin-top: 12px;
	padding-bottom: 30px;
}
