.GateBody {
	display: flex;
	width: 100%;
	min-width: 100px;
}

.DecimalsInput{
	border-left: 2px solid var(--kemu-color-widget-type-transformer);
	width: 40px
}

.DisabledWidget {
	border-left-color: var(--kemu-color-disabled-widget);
}