.Wrapper {
  padding: 0px 4px;
  width: 100%;
}

.SliderField{
	width: 100%;
}

/* .SliderField :global(.kemu-slider-track){
	background-color: var(--kemu-color-widget-type-producer-settings-border);
} */

.SliderField :global(.kemu-slider:hover .kemu-slider-track){
	background-color: var(--kemu-color-widget-type-producer-settings-container);
}

.SliderField :global(.kemu-slider-handle:hover)::after,
.SliderField :global(.kemu-slider-handle:focus)::after {
	box-shadow: 0 0 0 4px var(--kemu-color-widget-type-producer-settings-container);
}

.SliderField :global(.kemu-slider-handle)::after {
	border-color: var(--kemu-color-widget-type-producer-settings-border);
	box-shadow: 0 0 0 2px var(--kemu-color-widget-type-producer-settings-border);
}

label.Label {
  margin-bottom: 0px;
  margin-top: 0.4rem;
}

.Label.DarkLabel {
  color: var(--kc-canvas-gate-port-label);
}