.Icon svg {
  fill: #e8e8e8;
  font-size: 20px;
}

.Handle {
  position: absolute;
  right: 0px;
  bottom: 0px;
  cursor: se-resize;
  height: 20px;
}