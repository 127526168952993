.Container { 
	/* background-color: var(--kemu-color-primary); */
	background-color: var(--kemu-color-secondary);
	color: white;
}

.Container :global(a) {
	color: white;
	font-weight: 800;
	text-decoration: underline;
}

.Container :global(.ant-notification-notice-message) {
	color: white;
}


.NotificationContainer {
	display: flex;
	flex-direction: row;
}

.NotificationContainer .Icon{
	font-size: 70px;
	padding-right: 15px;
}

.NotificationContainer .Body{
	padding-top: 5px;
	display: block;
}

.NotificationContainer .Title {
	font-size: 16px;
}