
.FilterItem:hover {
	background-color: #f0f0f0;
}

.FilterItem {
	display: flex;
	flex-direction: row;
	padding: 4px 4px;
	border-radius: 3px;
	user-select: none;
}

.Overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 32px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}

.Dragger {
	cursor: grab;
	margin-right: 8px;
	margin-left: 4px;
	opacity: 0.4;
}


.FilterValueInput {
	border: none;
	outline: none;
	width: 40px;
	padding: 4px;
	border-radius: 4px;
	text-align: center;
	background-color: #e8e8e8 !important;
	color: black !important;
	margin: 0px 2px;
}



.FilterValue:hover span {
	color: #a9c8ff;
}

.FilterValue {
	margin-left: 4px;
}

.FilterValue span {
	cursor: ew-resize;
	text-decoration: underline;
}

.RemoveBtn {
	margin-left: auto;
}
