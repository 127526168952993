:root{

	--kemu-color-secondary: #FF773E;
	--kemu-color-secondary-lighten-10: #ff8551;
	--kemu-color-secondary-lighten-20: #ff9265;
	--kemu-color-secondary-lighten-30: #ffa078;
	--kemu-color-secondary-lighten-40: #ffad8b;
	--kemu-color-secondary-lighten-50: #ffbb9e;
	--kemu-color-secondary-lighten-80: #ffe4d8;
	--kemu-color-secondary-darken-10: #ff611e;
	--kemu-color-secondary-darken-20: #fe4b00;
	--kemu-color-secondary-darken-30: #de4200;
	--kemu-color-secondary-darken-80: #3f1300;

	--kemu-color-primary: #4e3eff;
	--kemu-color-primary-lighten-10: #6051ff;
	--kemu-color-primary-lighten-20: #7165ff;
	--kemu-color-primary-lighten-30: #8378ff;
	--kemu-color-primary-lighten-40: #958bff;
	--kemu-color-primary-lighten-50: #a69eff;
	--kemu-color-primary-lighten-80: #dcd8ff;
	--kemu-color-primary-darken-10: #311eff;
	--kemu-color-primary-darken-20: #1500fe;
	--kemu-color-primary-darken-30: #1200de;
	--kemu-color-primary-darken-80: #05003f;

	--kemu-color-danger: #fc544b;
	--kemu-color-danger-lighten-10: #fc655d;
	--kemu-color-danger-lighten-20: #fd766f;
	--kemu-color-danger-lighten-30: #fd8781;
	--kemu-color-danger-lighten-40: #fd9893;
	--kemu-color-danger-lighten-50: #fda9a5;
	--kemu-color-danger-lighten-80: #fedddb;
	--kemu-color-danger-darken-10: #fb352b;
	--kemu-color-danger-darken-20: #fb170b;
	--kemu-color-danger-darken-30: #e10f04;
	--kemu-color-danger-darken-80: #400401;

	--kemu-color-warning: #ffa426;
	--kemu-color-warning-lighten-10: #ffad3c;
	--kemu-color-warning-lighten-20: #ffb651;
	--kemu-color-warning-lighten-30: #ffbf67;
	--kemu-color-warning-lighten-40: #ffc87d;
	--kemu-color-warning-lighten-50: #ffd192;
	--kemu-color-warning-lighten-80: #ffedd4;
	--kemu-color-warning-darken-10: #ff9809;
	--kemu-color-warning-darken-20: #ea8800;
	--kemu-color-warning-darken-30: #cd7700;
	--kemu-color-warning-darken-80: #3b2200;

	--kemu-color-success: #52c41a;
	--kemu-color-success-lighten-10: #5ddd1d;
	--kemu-color-success-lighten-20: #6ee434;
	--kemu-color-success-lighten-30: #80e74d;
	--kemu-color-success-lighten-40: #92eb66;
	--kemu-color-success-lighten-50: #a4ee80;
	--kemu-color-success-lighten-60: #b6f299;
	--kemu-color-success-lighten-70: #c8f5b3;
	--kemu-color-success-lighten-80: #dbf8cc;

	--kemu-color-light: #e3eaef;
	--kemu-color-light-lighten-10: #e6ecf1;
	--kemu-color-light-lighten-20: #e9eef2;
	--kemu-color-light-lighten-30: #ebf0f4;
	--kemu-color-light-lighten-40: #eef2f5;
	--kemu-color-light-lighten-50: #f1f4f7;
	--kemu-color-light-lighten-60: #f4f7f9;
	--kemu-color-light-lighten-70: #f7f9fa;
	--kemu-color-light-lighten-80: #f9fbfc;
	--kemu-color-light-darken-10: #c5d4de;
	--kemu-color-light-darken-20: #a8bdcd;
	--kemu-color-light-darken-30: #8aa7bc;
	--kemu-color-light-darken-60: #446177;
	--kemu-color-light-darken-70: #334959;
	--kemu-color-light-darken-80: #22313b;
	

	--kemu-color-gray: #bbb;
	--kemu-color-gray-darken-10: #a8a8a8;
	--kemu-color-gray-darken-20: #969696;
	--kemu-color-gray-darken-30: #838383;

	--kemu-color-dark: #212529;


	--kemu-color-block-dark-0: #71A6FF;
	--kemu-color-block-light-0: #A4C6FF;
	--kemu-color-block-text-0: #fff;

	--kemu-color-block-dark-1: #B27DFF;
	--kemu-color-block-light-1: #D0B0FF;
	--kemu-color-block-text-1: #fff;

	--kemu-color-block-dark-2: #FF78B0;
	--kemu-color-block-light-2: #FFABCE;
	--kemu-color-block-text-2: #fff;

	--kemu-color-block-dark-3: #FF875D;
	--kemu-color-block-light-3: #FFAD90;
	--kemu-color-block-text-3: #fff;
	
	--kemu-color-block-dark-4: #6BF6B3;
	--kemu-color-block-light-4: #9BF9CC;
	--kemu-color-block-text-4: #5f686e;

	/* Random */
	--kemu-color-block-dark-5: #CA597F;
	--kemu-color-block-light-5: #D6809D;
	--kemu-color-block-text-5: #fff;

	--kemu-color-block-dark-6: #FC5A4E;
	--kemu-color-block-light-6: #FD8980;
	--kemu-color-block-text-6: #fff;
	
	--kemu-color-block-dark-7: #74E96F;
	--kemu-color-block-light-7: #9ff09b;
	--kemu-color-block-text-7: #fff;
	
	--kemu-color-block-dark-8: #E9538D;
	--kemu-color-block-light-8: #ef80ab;
	--kemu-color-block-text-8: #fff;
	
	--kemu-color-block-dark-9: #5691DB;
	--kemu-color-block-light-9: #80ACE4;
	--kemu-color-block-text-9: #fff;
	
	--kemu-color-block-dark-10: #BEBC68;
	--kemu-color-block-light-10: #CDCC8C;
	--kemu-color-block-text-10: #323470;

	--kemu-color-disabled-widget: #bbb;
	--kemu-color-enabled-widget: #6051ff;
	--kemu-color-original-event-widget: #52c41a;

	--kemu-color-widget-invoked: #6051ff;

	/* Standard canvas mode */
	--kc-canvas-bg: white;
	--kc-canvas-grid-color: #0000000f;
	--kc-canvas-gate-port-label: #6d6a6a;
	--kc-canvas-breadcrumb-color: var(--kemu-color-primary);
	--kc-canvas-breadcrumb-inactive-color: #6c757d;
	
	/* --color1: hsla(207, 90%, 61%, 1);
	--color1: hsla(291, 47%, 51%, 1);
	--color1: hsla(187, 71%, 50%, 1);
	--color1: hsla(123, 38%, 57%, 1);
	--color1: hsla(36, 100%, 57%, 1);
	--color1: hsla(14, 100%, 63%, 1); */
	
	/* Widget colors */
	--kemu-color-widget-type-logic: hsla(207, 90%, 61%, 1);
	--kemu-color-widget-type-logic-settings-container: hsla(207, 90%, 61%, 1);
	--kemu-color-widget-type-logic-settings-input: hsla(207, 90%, 46%, 1);
	--kemu-color-widget-type-logic-settings-border: hsla(207, 90%, 56%, 1);
	
	--kemu-color-widget-type-custom: hsla(245, 100%, 62%, 1);
	--kemu-color-widget-type-custom-settings-container: hsla(245, 100%, 62%, 1);
	--kemu-color-widget-type-custom-settings-input: hsla(245, 100%, 47%, 1);
	--kemu-color-widget-type-custom-settings-border: hsla(245, 100%, 57%, 1);

	--kemu-color-widget-type-condition: hsla(291, 47%, 51%, 1);
	--kemu-color-widget-type-condition-settings-container: hsla(291, 47%, 51%, 1);
	--kemu-color-widget-type-condition-settings-input: hsla(291, 47%, 36%, 1); 	/* -15% */
	--kemu-color-widget-type-condition-settings-border: hsla(291, 47%, 46%, 1);  /* -5% */

	--kemu-color-widget-type-producer: hsl(0deg 1% 38%);
	--kemu-color-widget-type-producer-settings-container: hsl(0deg 1% 38%);
	--kemu-color-widget-type-producer-settings-input: hsl(0deg 1% 23%);
	--kemu-color-widget-type-producer-settings-border: hsl(0deg 1% 33%);
	
	--kemu-color-widget-type-consumer: hsla(123, 38%, 57%, 1);
	--kemu-color-widget-type-consumer-settings-container: hsla(123, 38%, 57%, 1);
	--kemu-color-widget-type-consumer-settings-input: hsla(123, 38%, 42%, 1);
	--kemu-color-widget-type-consumer-settings-border: hsla(123, 38%, 52%, 1);

	--kemu-color-widget-type-storage: hsla(47, 80%, 58%, 1);
	--kemu-color-widget-type-storage-settings-container: hsla(47, 80%, 58%, 1);
	--kemu-color-widget-type-storage-settings-input: hsla(47, 80%, 43%, 1);
	--kemu-color-widget-type-storage-settings-border: hsla(47, 80%, 53%, 1);

	--kemu-color-widget-type-transformer: hsla(0, 100%, 72%, 1);
	--kemu-color-widget-type-transformer-settings-container: hsla(0, 100%, 72%, 1);
	--kemu-color-widget-type-transformer-settings-input: hsla(0, 100%, 66%, 1);
	--kemu-color-widget-type-transformer-settings-border: hsla(0, 100%, 67%, 1);

	/* Inside Custom Widgets */
	--kc-custom-widget-mode-canvas-bg: #3a4b67;
	--kc-custom-widget-mode-canvas-grid-color: #ffffff0f;
	--kc-custom-widget-mode-canvas-gate-port-label: #fff;
	--kc-custom-widget-mode-canvas-breadcrumb-color: #968dff;
	--kc-custom-widget-mode-canvas-breadcrumb-inactive-color: #a5b1bb;
}

.use-vb-color.c-0 .cb-container{ box-shadow: 0px 0px 1px 1px var(--kemu-color-block-dark-0);  }
.use-vb-color.c-0 .cb-header{ background-color: var(--kemu-color-block-light-0);  }
.use-vb-color.c-0 .cb-label{ color: var(--kemu-color-block-text-0);  }
.use-vb-color.c-0 .cb-body{ background-color: var(--kemu-color-block-dark-0);  }

.use-vb-color.c-1 .cb-container{ box-shadow: 0px 0px 1px 1px var(--kemu-color-block-dark-1);  }
.use-vb-color.c-1 .cb-header{ background-color: var(--kemu-color-block-light-1);  }
.use-vb-color.c-1 .cb-label{ color: var(--kemu-color-block-text-1);  }
.use-vb-color.c-1 .cb-body{ background-color: var(--kemu-color-block-dark-1);  }

.use-vb-color.c-2 .cb-container{ box-shadow: 0px 0px 1px 1px var(--kemu-color-block-dark-2);  }
.use-vb-color.c-2 .cb-header{ background-color: var(--kemu-color-block-light-2);  }
.use-vb-color.c-2 .cb-label{ color: var(--kemu-color-block-text-2);  }
.use-vb-color.c-2 .cb-body{ background-color: var(--kemu-color-block-dark-2);  }

.use-vb-color.c-3 .cb-container{ box-shadow: 0px 0px 1px 1px var(--kemu-color-block-dark-3);  }
.use-vb-color.c-3 .cb-header{ background-color: var(--kemu-color-block-light-3);  }
.use-vb-color.c-3 .cb-label{ color: var(--kemu-color-block-text-3);  }
.use-vb-color.c-3 .cb-body{ background-color: var(--kemu-color-block-dark-3);  }

.use-vb-color.c-4 .cb-container{ box-shadow: 0px 0px 1px 1px var(--kemu-color-block-dark-4);  }
.use-vb-color.c-4 .cb-header{ background-color: var(--kemu-color-block-light-4);  }
.use-vb-color.c-4 .cb-label{ color: var(--kemu-color-block-text-4);  }
.use-vb-color.c-4 .cb-body{ background-color: var(--kemu-color-block-dark-4);  }

.use-vb-color.c-5 .cb-container{ box-shadow: 0px 0px 1px 1px var(--kemu-color-block-dark-5);  }
.use-vb-color.c-5 .cb-header{ background-color: var(--kemu-color-block-light-5);  }
.use-vb-color.c-5 .cb-label{ color: var(--kemu-color-block-text-5);  }
.use-vb-color.c-5 .cb-body{ background-color: var(--kemu-color-block-dark-5);  }

.use-vb-color.c-6 .cb-container{ box-shadow: 0px 0px 1px 1px var(--kemu-color-block-dark-6);  }
.use-vb-color.c-6 .cb-header{ background-color: var(--kemu-color-block-light-6);  }
.use-vb-color.c-6 .cb-label{ color: var(--kemu-color-block-text-6);  }
.use-vb-color.c-6 .cb-body{ background-color: var(--kemu-color-block-dark-6);  }

.use-vb-color.c-7 .cb-container{ box-shadow: 0px 0px 1px 1px var(--kemu-color-block-dark-7);  }
.use-vb-color.c-7 .cb-header{ background-color: var(--kemu-color-block-light-7);  }
.use-vb-color.c-7 .cb-label{ color: var(--kemu-color-block-text-7);  }
.use-vb-color.c-7 .cb-body{ background-color: var(--kemu-color-block-dark-7);  }

.use-vb-color.c-8 .cb-container{ box-shadow: 0px 0px 1px 1px var(--kemu-color-block-dark-8);  }
.use-vb-color.c-8 .cb-header{ background-color: var(--kemu-color-block-light-8);  }
.use-vb-color.c-8 .cb-label{ color: var(--kemu-color-block-text-8);  }
.use-vb-color.c-8 .cb-body{ background-color: var(--kemu-color-block-dark-8);  }

.use-vb-color.c-9 .cb-container{ box-shadow: 0px 0px 1px 1px var(--kemu-color-block-dark-9);  }
.use-vb-color.c-9 .cb-header{ background-color: var(--kemu-color-block-light-9);  }
.use-vb-color.c-9 .cb-label{ color: var(--kemu-color-block-text-9);  }
.use-vb-color.c-9 .cb-body{ background-color: var(--kemu-color-block-dark-9);  }

.use-vb-color.c-10 .cb-container{ box-shadow: 0px 0px 1px 1px var(--kemu-color-block-dark-10);  }
.use-vb-color.c-10 .cb-header{ background-color: var(--kemu-color-block-light-10);  }
.use-vb-color.c-10 .cb-label{ color: var(--kemu-color-block-text-10);  }
.use-vb-color.c-10 .cb-body{ background-color: var(--kemu-color-block-dark-10);  }


/* .ant-btn-primary {
	background: var(--kemu-color-secondary);
	border-color: var(--kemu-color-secondary);
} */

/* 4. layouts Style */
/* 4.1 Misc */
/* nunito-regular - latin */
@font-face {
	font-family: 'Nunito';
	font-style: normal;
	font-weight: 400;
	src: url("../../assets/fonts/nunito-v9-latin-regular.eot");
	/* IE9 Compat Modes */
	src: local("Nunito Regular"), local("Nunito-Regular"), url("../../assets/fonts/nunito-v9-latin-regular.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/nunito-v9-latin-regular.woff2") format("woff2"), url("../../assets/fonts/nunito-v9-latin-regular.woff") format("woff"), url("../../assets/fonts/nunito-v9-latin-regular.ttf") format("truetype"), url("../../assets/fonts/nunito-v9-latin-regular.svg#Nunito") format("svg");
	/* Legacy iOS */
}

/* nunito-600 - latin */
@font-face {
	font-family: 'Nunito';
	font-style: normal;
	font-weight: 600;
	src: url("../../assets/fonts/nunito-v9-latin-600.eot");
	/* IE9 Compat Modes */
	src: local("Nunito SemiBold"), local("Nunito-SemiBold"), url("../../assets/fonts/nunito-v9-latin-600.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/nunito-v9-latin-600.woff2") format("woff2"), url("../../assets/fonts/nunito-v9-latin-600.woff") format("woff"), url("../../assets/fonts/nunito-v9-latin-600.ttf") format("truetype"), url("../../assets/fonts/nunito-v9-latin-600.svg#Nunito") format("svg");
	/* Legacy iOS */
}

/* nunito-700 - latin */
@font-face {
	font-family: 'Nunito';
	font-style: normal;
	font-weight: 700;
	src: url("../../assets/fonts/nunito-v9-latin-700.eot");
	/* IE9 Compat Modes */
	src: local("Nunito Bold"), local("Nunito-Bold"), url("../../assets/fonts/nunito-v9-latin-700.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/nunito-v9-latin-700.woff2") format("woff2"), url("../../assets/fonts/nunito-v9-latin-700.woff") format("woff"), url("../../assets/fonts/nunito-v9-latin-700.ttf") format("truetype"), url("../../assets/fonts/nunito-v9-latin-700.svg#Nunito") format("svg");
	/* Legacy iOS */
}

/* nunito-800 - latin */
@font-face {
	font-family: 'Nunito';
	font-style: normal;
	font-weight: 800;
	src: url("../../assets/fonts/nunito-v9-latin-800.eot");
	/* IE9 Compat Modes */
	src: local("Nunito ExtraBold"), local("Nunito-ExtraBold"), url("../../assets/fonts/nunito-v9-latin-800.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/nunito-v9-latin-800.woff2") format("woff2"), url("../../assets/fonts/nunito-v9-latin-800.woff") format("woff"), url("../../assets/fonts/nunito-v9-latin-800.ttf") format("truetype"), url("../../assets/fonts/nunito-v9-latin-800.svg#Nunito") format("svg");
	/* Legacy iOS */
}

:root {
	/* Colors */
	--primary: color(primary);
	--secondary: color(fontdark);
	--success: color(success);
	--info: color(info);
	--warning: color(warning);
	--danger: color(danger);
	--light: color(light);
	--dark: color(dark);
}

body {
	background-color: #F6F6F6;
	font-size: 14px;
	font-weight: 400;
	font-family: 'Nunito', 'Segoe UI', arial;
	color: #6c757d;
	/* Prevent navigation on swap (Not supported by Safari) */
	overscroll-behavior-x: none;
	/* Prevent pull down animation */
	overscroll-behavior-y: none;
	overflow: hidden;
	margin: 0px;
	height: 100vh;
}

body > #root {
	height: 100%;
}


header.site-header .trigger-btn{
	margin-right: auto;
	color: white;
}

header.site-header .trigger-btn .trigger {
	font-size: 18px;
	line-height: 64px;
	padding: 0 24px;
	cursor: pointer;
	transition: color 0.3s;
}

header.site-header .trigger-btn .trigger:hover {
	color: #1890ff;
}

.logo {
	height: 32px;
	background: rgba(255, 255, 255, 0.3);
	margin: 16px;
}

/* .site-layout .site-layout-background {
	background: #fff;
} */

.ant-layout-content.site-layout-background {
	background: #fff;
}

.kemu-workspace {
	box-shadow: 0 4px 25px 0 rgb(0 0 0 / 10%);
	border-radius: 10px;
	overflow: hidden;
}


/* Generic */
a {
	color: var(--kemu-color-primary);
	font-weight: 500;
	transition: all .5s;
	-webkit-transition: all .5s;
	-o-transition: all .5s;
}

a:hover{
	color: var(--kemu-color-primary-lighten-30);
}

.ant-btn-link{
	color: var(--kemu-color-primary-lighten-20);
}

.ant-btn-link:hover, .ant-btn-link:focus{
	color: var(--kemu-color-primary-lighten-30);
}

.ant-btn:hover, .ant-btn:focus{
	/* color: var(--kemu-color-primary-lighten-30); */
}

img {
	vertical-align: middle;
	border-style: none;
}

p, ul:not(.list-unstyled), ol {
	line-height: 28px;
}

.text-muted{
	color: #98a6ad !important;
}

/* 4.2 Section */
.section {
	position: relative;
	z-index: 1;
}

.section>*:first-child {
	margin-top: -7px;
}

.section .section-header {
	padding: 20px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;

	/* display: flex; */
}

.section .section-header h1 {
	margin-bottom: 0;
	font-weight: 700;
	display: inline-block;
	font-size: 24px;
	margin-top: 3px;
	color: #34395e;
}

.section .section-header .section-header-back {
	margin-right: 15px;
}

.section .section-header .section-header-back .btn:hover {
	background-color: #6777ef;
	color: #fff;
}

.section .section-header .section-header-button {
	margin-left: 20px;
}


.section .section-header .btn {
	font-size: 12px;
}

.section .section-title {
	font-size: 18px;
	color: #191d21;
	font-weight: 600;
	position: relative;
	margin: 30px 0 25px 0;
}

.section .section-title+.section-lead {
	margin-top: -20px;
}

.main-wrapper-1 .section .section-header {
	margin-left: -30px;
	margin-right: -30px;
	margin-top: -10px;
	border-radius: 0;
	border-top: 1px solid #f9f9f9;
	padding-left: 35px;
	padding-right: 35px;
}

@media (max-width: 575.98px) {
	.section .section-title {
		font-size: 14px;
	}

	.section .section-header {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		margin-bottom: 20px !important;
	}

	.section .section-header h1 {
		font-size: 18px;
	}

	.section .section-header .float-right {
		display: inline-block;
		width: 100%;
		margin-top: 15px;
	}
}

.dark-theme a {
	color: #96a2b4;
}

/* section.site-layout header {
	background-color: var(--kemu-color-primary);
}

section.site-layout header .anticon-menu-unfold.trigger svg,
section.site-layout header .anticon-menu-fold.trigger svg{
	fill: white;
} */

.ant-layout{
	background-color: transparent;
}

/* .section .section-header h1 {
	color: #34395e;
} */

/* Dark mode */
/* .dark-theme section.site-layout header {
	background-color: #2c333a;
}

.dark-theme section.site-layout .section-header {
	background-color: #38424b;
} */



.dark-theme .section .section-header h1{
	color: #96a2b4;
}


#layout-container {
	background-color: #F6F6F6;
	height: 100%;
}

#layout-container .layout-section{
	height: 100vh;
	overflow: hidden;
	position: relative;
}

#layout-container.dark-theme {
	background-color: #38424b;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.kemu-things-install-notification{
	max-height: 90vh;
  overflow-y: auto;
}

.kemu-thing-notification .ant-message-notice-content{
	text-align: left;
}

/* Change default freshchat widget */
#fc_frame.fc-widget-normal {
	z-index: 500 !important;
}

h1, h2, h3, h4, h5, h6 {
	font-family: 'Nunito', 'Segoe UI', arial;
	margin-top: 0;
}

* {
	box-sizing: border-box;
}