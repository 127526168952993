:root {
	--widget-launchpad-section-color: white;
	--widget-launchpad-section-bg-color: hsl(0deg 0% 95%);
	--widget-launchpad-section-header-color: #60686f;
	--widget-launchpad-button-text-color: #767676;
	--widget-launchpad-border-color: hsl(0deg 0% 90%);
}

.BarIcon {
	font-size: 34px;
}

.LaunchpadContainer {
	display: flex;
  flex-direction: column;
	justify-content: space-between;

	width: 500px;
}

.WidgetsGrid {
	width: 100%;
	min-height: 150px;
	display: flex;
	flex-wrap: wrap;
	overflow: auto;
	align-content: flex-start;
	/* height: calc(50vh - 120px); */
	margin-top: 10px;
	justify-content: flex-start;
	padding-top: 20px;
  padding-left: 16px;

	height: calc(100% - 100px);
	overflow-y: auto;
}

.WidgetsGrid::-webkit-scrollbar {
	/* width: 6px;
	background-color: #f6f6f6; */
	display: none;
}

/* .WidgetsGrid::-webkit-scrollbar-thumb {
	background-color: #999da2;
	border-radius: 10px;
} */


.LaunchpadButton {
	background-color: var(--kemu-color-primary);
}

.LaunchpadOverlay {
	/* Allow modals (with 1000 z-index) to appear on top */
	z-index: 1000;
}

.LaunchpadOverlay :global(.ant-popover-inner-content){
	padding: 0px;
}

.LaunchpadOverlay :global(.ant-popover-inner){
	/* background-color: var(--widget-launchpad-bg-color); */
	/* background-color: white; */

	background-color: var(--widget-launchpad-section-bg-color);
	box-shadow: 0 3px 5px -4px rgb(0 0 0 / 30%), 0 6px 16px 0 rgb(0 0 0 / 20%), 0 9px 28px 8px rgb(0 0 0 / 23%) !important;

  /* box-shadow: 1px 1px 5px 1px #60686f; */
}

.LaunchpadOverlay :global(.ant-popover-arrow) {
	border-color: var(--widget-launchpad-section-color) !important;
}


.EmptyWidgetsContainer { 
	width: 100%;
	/* height: 250px; */
	text-align: center;
	display: flex;
	flex-direction: column;
	color: var(--widget-launchpad-section-header-color);
	justify-content: center;
	align-items: center;
	margin-left: -16px;
}

.LearnHowButton {
	width: 100px;
	margin-top: 10px;
}

.LaunchpadFooter {
	/* width: 500px; */
	min-height: 100px;
	background-color: var(--widget-launchpad-section-color);
	position: relative;
	bottom: 0px;
	left: 0px;
	padding: 15px 15px;
	color: var(--widget-launchpad-section-header-color);
	border-top: solid 1px var(--widget-launchpad-border-color);
}

.LaunchpadFooter .Description {
	width: calc(100% - 32px);
	height: 100%;
  overflow-y: auto;
	color: var(--widget-launchpad-button-text-color, gray);
}


.Description::-webkit-scrollbar {
	display: none;
}

.Default {
	font-style: italic;
}

.FooterActionButtons {
	width: 32px;
	height: 100%;
	/* background-color: yellow; */
	top: 0px;
	position: absolute;
	display: flex;
	right: 0px;
	justify-content: flex-start;
	flex-direction: column;
}


.FooterActionButtons button,
.FooterActionButtons button:hover, 
.FooterActionButtons button:focus {
	color: var(----widget-launchpad-section-header-color);
}

.FooterActionButtons button:global(.sharingOn) {
	color: var(--kemu-color-success);
}

.FooterActionButtons button:hover {
	background-color: #fcddcf;
}

.FooterTitle {
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 5px;
}


.WidgetsSection {
	min-height: 150px;

	height: 100%;
}
/* 

.Panel {
	border-bottom: 1px solid var(--widget-launchpad-border-color) !important;
}

.Panel:last-child {
	border-bottom: unset !important;
}

.Panel :global(.ant-collapse-content-box){
	padding: 0px;
	background-color: inherit;
}

.Panel :global(.ant-collapse-content){
	background-color: inherit;
	border: none;
}

.Panel:global(.ant-collapse-item-active .ant-collapse-header){
	box-shadow: 0px 0px 5px 2px rgb(0 0 0 / 10%);
	border-bottom: unset;
	border-radius: 0px !important;
}

.Panel :global(.ant-collapse-header span.ant-collapse-arrow) {
	font-size: 1.2em !important;
}

.Panel :global(.ant-collapse-header) {
	color: var(--widget-launchpad-section-header-color) !important;

	position: sticky !important;
	top: 0px;
	left: 0px;
	overflow: auto;
	background-color: var(--widget-launchpad-section-color);
	z-index: 101;
	border-bottom: unset;
} */


.LaunchpadCollapse {
	background-color: inherit;
	border-radius: 0px;
	border: none;
}

.LaunchpadCollapse :global(.kemu-collapse-content) {
	background-color: var(--widget-launchpad-section-bg-color);
}


.ExpandableArea {
	/* max-height: 50vh; */
	overflow: hidden;
}

.ExpandableArea::-webkit-scrollbar {
	display: none;
}

/* .HeaderName {
	margin-left: 14px;
} */

.PanelHeader svg {
	fill: var(--widget-launchpad-section-header-color);
}

.NotAvailable {
	color: inherit;
	font-size: smaller;
	display: inline-block;
	margin-left: 5px;
	font-weight: normal;
}

.SearchInputWrapper {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding-top: 5px;
	/* padding-left: 16px; */
}

.SearchInput {
	width: 100%;
	max-width: 150px;
	border-radius: 25px;
	border: none;
	padding: 3px 10px;
	opacity: 0.6;
	transition: all 0.3s ease-in-out;
	transition-property: opacity, padding, max-width;
}

.SearchInput:hover {
	opacity: 1;
}

/* Prevent border when active */
.SearchInput:focus {
	outline: none;
	padding: 5px 15px;
	max-width: 200px;
}