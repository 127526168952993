.WidgetBody {
	display: flex;
  min-width: 70px;
  min-height: 50px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: black;
	color: white;
}

.WidgetBody:global(.widget-disabled),
.WidgetBody:global(:not(.original-event)){
	border: none !important;
	background-color: transparent !important;
}

.WidgetBody:global(.original-event){
	border-radius: 7px;
}

/* .WidgetBody:global(.widget-disabled) .WidgetTypeContainer{
	background-color: var(--kemu-color-disabled-widget);
} */

.WidgetBody:global(:not(.widget-disabled).original-event) .BundleContainer{
	background-color: var(--kemu-color-original-event-widget);
}

.WidgetBody :global(.cg-body){
	background-color: transparent !important;
}

.WidgetBody:global(:not(.widget-disabled).widget-selected){
	background-color: var(--kemu-color-selected-widget);
}

.WidgetBody .BundleContainer svg {
	width: 50px;
	height: 50px;
}

.BundleContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.BundleContainer .MissingProcessor {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	height: 100%;
	width: 100%;
	text-align: center;
	font-weight: 600;
	font-size: 0.9em;
}

.SettingsContainer {
	width: 132px;
	min-height: 146px;
	padding: 20px 10px 10px 10px;
	position: relative;
	left: -2px;
	top: -2px;
	background-color: black;
	height: calc(100% + 4px);
}

.SettingsContainer label{
	color: white;
}

.BarIconWrapper {
	background-color: black;
}

.WidgetWrapper {
	background-color: black;
	border: 2px solid black;
}

.WidgetWrapper:global(.widget-selected) {
	box-shadow: 0px 0px 7px 1px #333;
}

.Header {
	color: white;
	background-color: #000;
	width: 100%;
	text-align: center;
	font-weight: 700;
}

.Icon {
	width: 100%;
	height: 100%;
	text-align: center;
}

.Name {
	text-align: center;
	color: var(--kc-canvas-gate-port-label);
	/* max-width: 150px; */
	margin-top: 5px;
	position: absolute;
	bottom: -5px;
	transform: translateY(100%);
	/* width: max-content; */
	width: 150px;
}

.InitErrorWarning {
	position: absolute;
	bottom: -10px;
	left: 50%;
	translate: -50%;
}

.InitErrorWarning span {
	width: 30px;
	height: 30px;
	background-color: var(--kemu-color-warning);
	color: white;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 25px;
}