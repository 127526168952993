.GateBody {
  display: flex;
	width: 62px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: var(--kemu-color-widget-type-custom); */
  color: white;
	border-radius: 5px;
}

.WidgetDisabled {
  background-color: var(--kemu-color-disabled-widget);
}

.SettingsContainer {
	min-height: 130px;
	padding: 30px 10px 10px 10px;
	background-color: var(--kemu-color-primary-darken-80);
	position: relative;
	left: -45px;
	top: -2px;
	height: 230px;
	width: 250px;
	z-index: 2;
	/* Simulate gate border */
	border: 2px solid var(--kemu-color-primary-lighten-10);
	
	display: flex;
  flex-direction: column;
}

.Icon {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	/* Enough space for the title */
	margin-top: 10px;
}

.Icon svg {
	width: 32px;
	height: 32px;
}

.Tooltip .Header {
	font-size: 16px;
	font-weight: bold;
	margin-bottom: 10px;
}

.Tooltip .Description {
	font-size: 14px;
}

.BarTitle {
	font-size: 7pt;
	text-align: center;
	text-transform: uppercase;
	font-weight: bolder;
	/* transform: translateY(-5px); */
	position: absolute;
	top: 5px;
}

/* Force settings container above ports when a port is dynamically added */
.WidgetWrapper:global(.widget-disabled),
.WidgetWrapper:global(:not(.original-event)){
	border: none !important;
	background-color: transparent !important;
}

.WidgetWrapper:global(.original-event){
	border-radius: 7px;
}

.WidgetWrapper:global(.widget-disabled) .WidgetTypeContainer{
	background-color: var(--kemu-color-disabled-widget);
}

.WidgetWrapper:global(:not(.widget-disabled).original-event) .WidgetTypeContainer{
	background-color: var(--kemu-color-original-event-widget);
}

.WidgetWrapper :global(.cg-body){
	background-color: transparent !important;
}

.Invisible {
	display: none;
}

.BottomCaption {
	font-family: 'Nunito';
	font-style: normal;
	font-weight: 600;
	text-align: center;
	position: absolute;
	bottom: -25px;
	transform: translateY(50%);
	pointer-events: none;
	color: var(--kc-canvas-gate-port-label);

	width: 100%;
	/* min-width: 160px; */
}

.BottomCaption.InsideGroup {
	color: #f0e9e9;
}