.RecipeBody {
	box-shadow: 0 4px 25px 0 rgba(0,0,0,0.1);
	background-color: #fff;
	/* border-radius: 10px; */
	border: 0;
	position: relative;
	margin-bottom: 30px;
	max-width: 250px;
}

.Header {
	position: relative;
	overflow: hidden;
	height: 100px;

	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--kemu-color-gray-darken-30);
}

.HeaderIcons :global(.dynamic svg){
	fill: inherit;
	width: 40px;
	height: 40px;
	fill: #e9e9e9;
	margin: 0px 5px;
}

.HeaderIcons {
	display: flex;
	width: calc(100% - 20px);
	height: 100%;
	justify-content: center;
	align-items: center;
}

.ImageSvg {
	width: 40px;
}

/* .HeaderIcon {
	font-size: 60px;
} */

.Header :global(.ant-image){
	width: 100%;
}

.Details {
	background-color: inherit;
	padding: 20px;
	line-height: 24px;
}

.Title {
	margin-bottom: 10px;
	height: 48px;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	display: -webkit-box;
}

.Title h2 {
	font-size: 21px;
	font-weight: 500;
	line-height: 24px;
	margin-top: 0;
	margin-bottom: 0;
}

.Category{
	text-transform: uppercase;
	margin-bottom: 5px;
	letter-spacing: 1px;
	color: #34395e;
	line-height: 24px;
}

.Bullet{
	display: inline;
  margin: 0 4px;
}

.Bullet::after {
	content: '\2022'
}

.TimeContainer {
	display: block;
	line-height: 7px;
	padding-bottom: 10px;
}

.UserName {
	line-height: 10px;
}

.UserName, .DateInfo {
	font-size: 10px;
	color: #34395e;
	font-weight: 700;	
	display: inline;
}

.DateInfo {
	color: #b8b3ad;
	text-transform: none;
	/* line-height: 5px; */
}

.Image {
	width: 100%;
	height: auto;
	padding: 15px;
	text-align: center;
}


.CustomSvg svg path{
	fill: white;
}

.CircleButton {
	padding: 4px 0px;
}

button.CircleButton:hover{
	background: var(--kemu-color-secondary-lighten-10);
	border-color: transparent;
}


.DropdownMenu{
	min-width: 100px;
}

.DropdownMenu :global(li.ant-dropdown-menu-item){
	display: flex;
	align-items: center;
}

.DropdownMenu :global(li.ant-dropdown-menu-item span.anticon){
	font-size: 20px;
	margin-right: 12px;
}

.TutorialIcon {
	margin-right: 5px;
}

.TutorialIcon a{
	color: inherit;
}

.RejectedInfoBtn {
	margin-left: 7px;
	color: #fefefe;
	cursor: pointer;
}

.RejectedInfoBtn:not(:hover) span[role="img"] {
	transition: transform 0.2s;
	transform: scale(1);
}

.RejectedInfoBtn:hover:not(:active) span[role="img"] {
	color: white;
	transform: scale(1.2);
	transition: transform 0.2s;
}

.RejectedInfoBtn:hover:active span[role="img"]{
	transition: transform 0s;
	transform: scale(1.1);
}