.Wrapper {
	position: relative;
	display: inline-flex;
	cursor: pointer;
	align-items: center;
	overflow: hidden;
	justify-content: space-between;
}

.ActionName {
	white-space: nowrap;
	height: 41px;
	line-height: unset !important;
	text-align: left;
	width: 100%;
	padding-left: 10px;
	padding-right: 10px;
}

.Disabled {
	color: var(--kemu-color-disabled-widget) !important;
}

.ActionName:global(.logic) {
	color: var(--kemu-color-widget-type-logic);
}
.ActionName:global(.condition) {
	color: var(--kemu-color-widget-type-condition);
}
.ActionName:global(.producer) {
	color: var(--kemu-color-widget-type-producer);
}
.ActionName:global(.consumer) {
	color: var(--kemu-color-widget-type-consumer);
}
.ActionName:global(.storage) {
	color: var(--kemu-color-widget-type-storage);
}
.ActionName:global(.transformer) {
	color: var(--kemu-color-widget-type-transformer);
}

.ActionName:global(.ant-dropdown-open) .DropIcon {
	transition: transform 0.2s;
	transform: rotate(180deg);
}

span.DropIcon[custom-dropdown='yes']{
	margin-left: 10px;
}

.DropDownContainer {
	display: flex;
	justify-content: space-between;
	width: 100%;
	height: 35px;
  align-items: center;
}