.InputName {
	white-space: nowrap;
	height: 41px;
	line-height: unset !important;
	color: var(--kemu-color-widget-type-producer);

	width: calc(100% - 35px);
	text-align: left;
	border: none;
}

.InputName:focus,
.InputName:hover {
	border-color: unset;
	box-shadow: unset;
	/* color: transparent; */
}


.InputName input {
	padding-right: 10px;
	width: 115px;
}

span.DropIcon[custom-dropdown='yes']{
	position: absolute;
	top: calc(50% - 6px);
	right: 2px;
	margin-left: 10px;
	margin-right: 6px;
	cursor: pointer;
}


.InputName:global(.kemu-dropdown-open) .DropIcon {
	transition: transform 0.2s;
	transform: rotate(180deg);
}

.DeleteItemBtn {
	border: none;
	background-color: transparent;
	height: 26px;
	opacity: 0.6;
}

.DeleteItemBtn:hover{
	opacity: 1;
}

.DropdownMenu :global(li.kemu-dropdown-menu-item-selected){
	background-color: var(--kemu-color-widget-type-producer-settings-input);
	color: white;
}

.CanvasIconContainer {
	display: flex;
	justify-content: center;
	align-items: center;
}

.CanvasIconContainer :global(.icon){
	font-size: 24px;
}