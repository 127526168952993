.GroupBody {
  width: 130px;
  border-radius: 4px;
  overflow: hidden;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
}

.BrightBody {
  border: 2px solid;
}

.DisabledWidget .GroupBody {
  background-color: var(--kemu-color-disabled-widget);
}

.HasFreeWidth {
  width: auto;
}

.Content {
  display: flex;
  flex-direction: column;
  padding: 4px 5px;
  padding-bottom: 6px;
  gap: 5px;

  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  /* height: 100%; */
}

.Content.SingleElement:not(.MultiPort) {
  padding: 1px 2.5px;
  padding-bottom: 2px;
}

.Content.MultiPort {
  justify-content: center;
}

.FieldItem {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.FieldItem label {
  color: white;
}