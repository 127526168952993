.RecipeLoadWrapper {
	/* width: 60px; */
	/* height: 30px; */
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 8px;
	background-color: var(--kemu-color-widget-type-producer);
}

.RecipeLoadWrapper svg {
	width: 32px;
	height: 32px;
	color: white;
}